import { MatrikelAutocomplete, AddressAutocomplete, StednavnAutoComplete, AddressBoundingBoxAutocomplete, PostNRAutoComplete } from "src/managers/SearchBarGeneralPlugin";
import { actionAddPopup, actionSetShowUserLocation, MapitState, MapitStateContext } from "src/states/MapitState";
import { SearchBarGeneral, SearchBarGeneralPlugin } from "./SearchBarGeneral";
import { useCallback, useContext, useMemo } from "react";
import { feature } from "@turf/helpers";
import { AutocompleterKeys, LayerInfo } from "src/common/managers/Types";
import { MapDescriptionButton } from "./NavigationBarMicro";
import { BsArrowLeftRight } from "react-icons/bs";
import { center, distance } from "@turf/turf";
import { DataLayerClickFunction } from "./MapScreen";
import { MitLatLng } from "src/managers/MapFacade";
import { Gps } from "iconsax-react";

export function DeepLinkNavigationBar(props: {onCollapse: () => void}) {
   const {state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext);

   const onSearchCallback = useCallback((data:any) => {
      const searchBarBehaviour = mapitState.mapInfo?.searchBarBehaviour ?? "zoomToResult";
      if (searchBarBehaviour === "zoomToResult") {
         mapitState.map.zoomToData(featureFromGSearch(data), 150)
      } else if (searchBarBehaviour === "zoomToNearest") {
         let searchCenter = center(featureFromGSearch(data)).geometry.coordinates as [number, number];
         mapitState.map.zoomToData(nearestFeatureInState(searchCenter, mapitState).nearestFeature, 150);
      } else if (searchBarBehaviour === "openNearest") {
         let searchCenter = center(featureFromGSearch(data)).geometry.coordinates as [number, number];
         mapitState.map.zoomToData(nearestFeatureInState(searchCenter, mapitState).nearestFeature, 150);
         let x = nearestFeatureInState(searchCenter, mapitState);
         if (!x.nearestLayer) return;
         let fCenter = center(x.nearestFeature).geometry.coordinates as [number, number];
         let e = {lngLat: new MitLatLng(fCenter[1], fCenter[0]).getLngLat(), features: [{...x.nearestFeature, id:x.fId}]};
         DataLayerClickFunction(e, mapitState.mapInfo, x.nearestLayer, (b) => mapitStateDispatch(actionAddPopup(MitLatLng.fromM(e.lngLat), (b), true)))  
      }
   }, [mapitState.mapInfo?.searchBarBehaviour, mapitState.featureLayers]);

   const searchPlugins = mapitState.mapInfo?.searchBarAutocompleters;
   const autoCompleters:SearchBarGeneralPlugin[] = useMemo(() => {
      return (searchPlugins || []).map((ac) => {
         return getAutocompleterByKey(ac, onSearchCallback, mapitState.mapInfo?.boundingBoxWhenSaved || undefined);
      });
   },[searchPlugins, mapitState.map, onSearchCallback]);

   return (
      <>
   <div className="NavigationTopBar small" id="Mit-NavigationTopBar" style={{zIndex:1,pointerEvents:"none"}}>
      <div className={"OnMapButton Collapse"} onClick={() => props.onCollapse()} >
        <BsArrowLeftRight />
      </div>
      {mapitState.mapInfo?.showUserLocation ?
      <div className={"OnMapButton " + (mapitState.showUserLocation ? "ActiveImg":"")} 
         onClick={()=>{
            mapitStateDispatch(actionSetShowUserLocation(!mapitState.showUserLocation));
         }}
         >
         <Gps variant={mapitState.showUserLocation ? "Bold" : undefined} />
         </div>: null}
      {searchPlugins?.length ?
      <SearchBarGeneral
          showWindow={true}
          autocompleters={autoCompleters}
          callbackOnSelection = {(type:string, props:any) => { alert (`got callback Type:${type} props: ${JSON.stringify(props)}`)} }
          />
         : null}
      
      </div>
      <div style={{zIndex:1, position:"absolute", right:"0px", padding:"10px", gap:"10px", display:"flex", flexDirection:"column", alignItems:"center"}}>
      <MapDescriptionButton />
</div>
</>
   )
}



function getAutocompleterByKey(key: AutocompleterKeys,callback:(data:any)=>void, boundingBox?:[[number, number],[number, number]]):SearchBarGeneralPlugin {
   switch (key) {
      case "matrikel": return new MatrikelAutocomplete((data:any)=> {
         callback(data);
      });
      case "adresse": return new AddressAutocomplete((data:any)=> {
         callback(data);
      });
      case "lokaladresse": return new AddressBoundingBoxAutocomplete((data:any)=> {
         callback(data);
      }, boundingBox);
      case "stednavn": return new StednavnAutoComplete((data:any) => {
         callback(data);
      });
      case "postNr":  return new PostNRAutoComplete((data:any) => {
         callback(data);
      });
   }
}

function featureFromGSearch(data:any) {
   return feature(data.geometri) //gSearch uses geometri instead of geometry
}

function nearestFeatureInState(coords:[number, number], state: MapitState) {
   let keys = Object.keys(state.layers)
   let nearestDistance = Number.MAX_VALUE;
   let nearestFeature: any = undefined;
   let nearestLayer: LayerInfo | undefined = undefined;
   let fId = -1;
   for (let i = 0; i < keys.length; i++) {
      let layer = state.layers[keys[i]];
      if (layer.geoJson && layer.visible) {
         let nearest = nearestFeatureInCollection(coords, layer);
         if (nearest) {
            let dist = nearest.nearestDistance;
            if (dist < nearestDistance) {
               nearestDistance = dist;
               nearestFeature = nearest.nearestFeature;
               nearestLayer = layer;
               fId = nearest.fId
            }
         }
      }
   }
   return {nearestFeature, nearestDistance, nearestLayer, fId};
}

function nearestFeatureInCollection(coords:[number, number], layer: LayerInfo) {
   let from = coords;
   if (!layer.geoJson) return;
   let nearestDistance = Number.MAX_VALUE;
   let nearestFeature: any = undefined;
   let fId = -1;
   for (let i = 0; i < layer.geoJson.features.length; i++) {
      let feature = layer.geoJson.features[i];
      let to = center(feature).geometry.coordinates as [number, number];
      let dist = distance(from, to);
      if (dist < nearestDistance) {
         nearestDistance = dist;
         nearestFeature = feature;
         fId = i;
      }
   }
   return {nearestFeature, nearestDistance, fId};
}
import { MitLatLng } from "src/managers/MapFacade";
import { FeatureLayer } from "src/managers/WmsLayerFunc";



/**
 * Global type definitions
 */

/**
 * Product Features
 * 
 * NOTE: the feature codes are stored on saved maps. Don't renumber the features.
 */
// todo: change feature codes to strings (such that codes are not changed if rearranging order)


export enum ImportDataType {
    Unknown="Unknown",
    Integer="Integer",
    Float="Float",
    String="String"
}

export enum MitDataType {
    Unknown="Unknown",
    Coord_WGS84_Lat="Coord_WGS84_Lat",
    Coord_WGS84_Lon="Coord_WGS84_Lon",
    Coord_UTM32_X="Coord_UTM32_X",
    Coord_UTM32_Y="Coord_UTM32_Y",
    AdmReg_DK_MunicipalityId="AdmReg_DK_MunicipalityId",
    AdmReg_DK_MunicipalityName="AdmReg_DK_MunicipalityName",
    AdmReg_DK_RegionId="AdmReg_DK_RegionId",
    AdmReg_DK_RegionName="AdmReg_DK_RegionName",
    AdmReg_DK_ZipCodeId="AdmReg_DK_ZipCodeId",
    AdmReg_DK_ZipCodeName="AdmReg_DK_ZipCodeName",
    AdmReg_DK_ParishId="AdmReg_DK_ParishId",
    AdmReg_DK_ParishName="AdmReg_DK_ParishName",
    AdmReg_INT_CountryName="AdmReg_INT_CountryName",
    AdmReg_INT_CountryId="AdmReg_INT_CountryId",
    AdmReg_RO_MunicipalityId="AdmReg_RO_MunicipalityId",
    AdmReg_RO_MunicipalityName="AdmReg_RO_MunicipalityName",
    AdmReg_DK_And_Sydslesvig_MunicipalityId="AdmReg_DK_And_Sydslesvig_MunicipalityId",
    AdmReg_DK_And_Sydslesvig_MunicipalityName="AdmReg_DK_And_Sydslesvig_MunicipalityName",
    Number="Number", 
    String="String",
    String_Category="Category",
    String_HTTP="Http",
    Address="Address",
    Address2="Address2",
    Address3="Address3",
    Address4="Address4",
    AddressesConcatenated="AddressesConcatenated",
    Value="Value",
    Value2="Value2",
    ValueHeight="ValueHeight",
    ValueText="ValueText",
    Label="Label",
    Label2="Label2",
    Label3="Label3",
    Label4="Label4",
    Label5="Label5",
    Label6="Label6",
    Label7="Label7",
    Label8="Label8",
    Label9="Label9",
    Label10="Label10",
    Label11="Label11",
    Label12="Label12",
    Label13="Label13",
    Label14="Label14",
    Label15="Label15",
    Label16="Label16",
    Label17="Label17",
    Label18="Label18",
    Label19="Label19",
    Label20="Label20",
    Row_Data_Quality="Row_Data_Quality",
    Row_Data_Quality_Note="Row_Data_Quality_Note",
    GeoCode_Result_Quality="GeoCode_Result_Quality",
    GeoCode_Result_Address="GeoCode_Result_Address",
    // GeoCodeResultLat="GeoCodeResultLat",
    // GeoCodeResultLon="GeoCodeResultLon",
    GeoJSON_Features_String="GeoJSON_Features_String",
    GeoJSON_Features="GeoJSON_Features",
    IconUrl="IconUrl",
    GridSizeMeters="GridSizeMeters",
    Ejerlavskode="Ejerlavskode",
    MatrikelNr="MatrikelNr",
    SfeEjendomsNr="SfeEjendomsNr",
    CatchmentWindowStartTime="CatchmentWindowStartTime",
    CatchmentWindowEndTime="CatchmentWindowEndTime",
    CatchmentTargetName="CatchmentTargetName",
    CatchmentFileName="CatchmentFileName",
}

export enum LayerType {
    Unknown = "Unknown",
    Point="Point",
    Area="Area",
    PointWGS84="PointWGS84",
    PointUTM32="PointUTM32",
    AreaMunicipality="AreaMunicipality",
    AreaRegion="AreaRegion",
    AreaZipcodes="AreaZipcodes",
    AreaMunicipalityId="AreaMunicipalityId",
    AreaRegionId="AreaRegionId",
    AreaZipcodesId="AreaZipcodesId",    
    AreaMunicipalityName="AreaMunicipalityName",
    AreaRegionName="AreaRegionName",
    AreaZipcodesName="AreaZipcodesName",   
    AreaCountry="AreaCountry", 
    AreaCountryName="AreaCountryName",
    AreaCountryId="AreaCountryId",
    GeoCode="GeoCode",
    GeoJSON="GeoJSON",
    GeoJSON_Point="GeoJSON_Point",
    GeoJSON_Polygon="GeoJSON_Polygon",
    GeoJSON_Line="GeoJSON_Line",

    AreaMunicipality_RO="AreaMunicipality_RO",
    AreaMunicipalityId_RO="AreaMunicipalityId_RO",
    AreaMunicipalityName_RO="AreaMunicipalityName_RO",
    AreaMunicipality_DK_And_SydSlesvig="AreaMunicipality_DK_And_SydSlesvig",
    AreaMunicipalityId_DK_And_SydSlesvig="AreaMunicipalityId_DK_And_SydSlesvig",
    AreaMunicipalityName_DK_And_SydSlesvig="AreaMunicipalityName_DK_And_SydSlesvig",
    AreaParish="AreaParish",
    AreaParishId="AreaParishId",
    AreaParishName="AreaParishName",

    Grid="Grid",

    PointCadaster="PointCadaster",
    AreaCadaster="AreaCadaster",
    AreaProperty="AreaProperty" // All cadasters that are part of the same SFE Samlet Fast Ejendom

}

export enum BackgroundLayer {
    Background_Viamap="Background_Viamap",
    Background_Contrast="BackgroundContrastt",
    Background_Light="Background_Light",
    Background_Dark="Background_Dark",
    Background_Aerial="Background_Aerial",
    Background_None="Background_None",
    // todo: remove below feature layers from list.
    AnvendelsesKoder="Anvendelseskoder",
    Ejerforhold="Ejerforhold",
    Cadaster="Cadaster",
    Zoning="Zoning",
}

export enum ProjectionType {
    Unknown = "Unknown",
    UTM32 = "UTM32",
    WGS84 = "WGS84",
}

export enum MarkerType {
//    Default = "Default",
    Pin="Pin",
    // Circle="Circle",
    // Square="Square",
    Icon="Icon"
}

export enum MappingPreference {
    Unknown,
    Neutral,
    Preferred,
    Default, // Used only if no Preferred options exist
    Unsuited
}

export type DataDivision = {
    from:number|null;       // Used for linear numeric divisions
    to:number|null;         // Used for linear numeric divisions
    value:string|null;      // Used for discrete values
    label?:string;          // Used for labels entered by user
    color?:string;          // Used for color entered by user
    hide?:boolean;          // Used to allow hiding groups from display
    count: number;          // The number of elements in this division
//    markerType: MarkerType; // Used for marker entered by user
};

export enum DataDivisionType { Continous, Discrete }

export type DataDivisionList = {
    type:DataDivisionType,
    divisionTypeSwapAble?: boolean,
    valuesAreNumbers?: boolean, // Used for discrete values. Where only numbers may be input as value
    otherValuesCount?:number, // Used for discrete values. The number of unqique values not assigned to a division 
    list:(DataDivision)[];
};

// Mapping to column index (0 = Column A, 1 = Column B, ...)
export type ColumnMapping = { [mitDataType:string]: zeroBasedColumnNumber};
export type zeroBasedColumnNumber = number;
// export enum ColumnSourceType { SheetColumn, CalculatedColumn };
// export type ColumnSource = { 
//     type: ColumnSourceType,
//     zeroBasedColumnIndex?:number,
//     mitdatatype?: MitDataType
// };

// export type LayerCreationResult = {
//     layerHandle:L.FeatureGroup<any>;
//     noOfItemsCreated: number;
//     noOfItemsFailed: number;
//     errorList: string[];
// };

export type MappingSpec = {
    dataElementsList: MappingSpecElement[];
};

export type MappingSpecElement = {
        name:string,
        mandatory:boolean,
        mitDataType:MitDataType,
};

export type SelectionListWithPrefs = 
        {
            value: string;
            label: string;
            comment?: string;
            preference?: MappingPreference;
        }[];

export type ValidationType = "success" | "warning" | "error" | null | undefined;

export type SelectionOptions = {
    heading:string,
    fieldselectionlist?: MappingSpecElement[];
    options?: {
    value:string;
    label:any;
    disabled?: boolean | string;
    weight: number;
    children?: SelectionOptions;
}[]
};

export type SheetAnalysisResultColumn = {
    columnLetter:string;
    name:string;
    firstRowsAsString:string[]; // data for first 10 rows
    dataType:string;
    minValue:number; // Of first 10 rows
    maxValue:number; // Of first 10 rows
    mitDataType: MitDataType;
    excelCellFormat?: string;
};

export type SheetAnalysisResult = null | {
    noOfRows:number;
    noOfColumns:number;
    headers: object[];
    columnNames:string[];
    columns: SheetAnalysisResultColumn[];
};

export enum LayerVariabilityType { NormalLayer, FixedFeatureLayer }
export type PropertyDisplaySpec = {
    name:string
}

/**
 * ImportTransformation is used to indicate any transformation to handle duplicates. E.g. more than one row for each Region when importing by area(Region).
 */
export type ImportTransformation = {
    action: ImportTransformationAction,
    transformation?: ImportTransformationType,
    propertyToTransform?: string,
    concatenationSeparator?: string
}
export enum ImportTransformationAction  {None="None", DuplicatesThrowAway="DuplicatesThrowAway", DuplicatesTransform="DuplicatesTransform"};
export enum ImportTransformationType  {None="None", Sum="Sum", Min="Min", Max="Max", Avg="Avg", Concatenate="Concatenate", Count="Count"};

export type LayerInfo = {
    
    layerId: number
    datasetname: string,
    group?: string[],
    filename?:string,
    handle?: any,
    readonly: boolean,
    hasNoDataBehind?: boolean, // false: imported from excel spread sheet (or similar)
    geometryReferenceFromMapit?: {geoType: LayerType},
    type: LayerType,
    columnMapping: ColumnMapping,
    analysisResult: SheetAnalysisResult,
    importTransformation? : ImportTransformation,
    datasheet?: any,
    data?: {},
    visible?:boolean,
    styling: LayerInfoStyling,
    crs?:number,
    importedSheetData?: {[columnLetter:string]:any},
    groupName?:string,
    layerVariability?: LayerVariabilityType,
    awsFilename?: string,


    // When data is stored as geoJson
    geoJson?:any

    // When data is deflated (geometries removed)
    geoJsonDeflated?: {
        geometryReferenceFromMapit: {geoType: LayerType}, // area types where geometry is not persisted in .mapit files. Re-created on import
        features:any
    },

    propertiesInGeoJson?:string[],
    propertiesToDisplay?:PropertyDisplaySpec[]
  };

export enum GroupVisibility { Visible, PartiallyVisible, NotVisible, NoChildren }

export type LayerHierarchy = {
    id: number,
    name: string,
    isCollapsed: boolean,
    isReadonly?: boolean,
    children: (LayerHierarchy | LayerHierarchyEndNode)[], // Number represents layerId
};

export type LayerHierarchyEndNode = {
    id: number,
    children?: []
};

export enum ClusteringType {
    Piechart,
    Normal,
    None
}

export type SpecialValueStyling = {
    show: boolean,
    color: string,
}

export type ReferenceToJsonProperty = string;

export type LayerInfoStylingOld = {
    hideAreasWithNoData?: boolean,
    areasWithNoDataColor?: string,
    valueOutsideRangeColor?: string,
} 

export type LayerInfoStyling = {
    markerType?: MarkerType,
    markerByValue?: MarkerByValueStyling,  
    color?: string,
    // label?: LabelStyling,
    labelTabs?: LabelTabStylingList,
    colorByValue?: ColorByValueStyling,
    heightByValue?: HeightByValueStyling,
    heightMultiplier?: number,
    sizeByValue?: SizeByValueStyling,
    textByValue?: TextByValueStyling,
    textColor?:string,
    areaFillColor?: string,
    areaFillOpacity?: number,
    areaBorderColor?: string,
    areaNoData?: SpecialValueStyling,
    outsideColorRange?: SpecialValueStyling,
    useClustering?: boolean,
    clusteringType?: ClusteringType,
    showElementCounts?:boolean,
    textFont?:string,

    iconSize?: number,
    opacity?: number,
  
    colorByProperty?:ReferenceToJsonProperty,
    sizeByProperty?: ReferenceToJsonProperty,
    iconByProperty?: ReferenceToJsonProperty,
    heightByProperty?: ReferenceToJsonProperty,
    textByProperty?: ReferenceToJsonProperty | ReferenceToJsonProperty[],

    lineOpacity?:number,
    lineWidth?:number,
    lineDashRatio?:number,
    lineDashMultitude?:number,
    lineColor?: string
};

export type MarkerByValueStyling = {
    dataColumn: zeroBasedColumnNumber,
};

export type ColorByValueStyling = {
    //            showInLegend?: boolean,
        dataColumn: zeroBasedColumnNumber,
        useColorRange?: number; // only if a standard range is used. Otherwise undefined.
        divisions?: DataDivisionList;
        divisionStylingType:DivisionStylingType,
        allowHideIndividualDivisions?:boolean,
        freezeDivisions?:boolean
    };

export type HeightByValueStyling = {
        dataColumn: zeroBasedColumnNumber
    };

export type TextByValueStyling = {
        dataColumn: zeroBasedColumnNumber
    };

export enum DivisionStylingType { Standard, Custom }

export type SizeByValueStyling = {
    //            showInLegend?: boolean,
        dataColumn?: zeroBasedColumnNumber,
        useSizeRange?:number,
        divisions?: DataDivisionList,
    };

export type LabelTabStylingList = LabelTabStyling[];

export type LabelTabStyling = {
        title:string;
        labels:LabelStyling
    };

export type LabelStyling = {
        labelDataList:LabelColumnStyling[],
    };

export type LabelColumnStyling = {
        labelDataColumn: zeroBasedColumnNumber,
        labelText: string,
    };

export enum PictureLayoutType { Left = "Left", Center = "Center", Right = "Right"}
export enum PictureSize { Small = "Small", Medium = "Medium", Large = "Large"}
export enum PopupLayout {
    ShowNothing = "ShowNothing",
    ShowAreaName = "ShowAreaName",
    ShowAreaCode = "ShowAreaCode", 
    ShowAll = "ShowAll"
}

export type AutocompleterKeys = "matrikel" | "adresse" | "lokaladresse" | "stednavn"|"postNr";
export type SearchBarBehaviour = "zoomToResult" | "zoomToNearest" | "openNearest" 

export type MapInfo = {
    popUpPictureLayout: PictureLayoutType;
    popUpPictureSize: PictureSize;
    popUpLayout: PopupLayout;
    mapTitle?:string;
    ownerID?:string;
    customerIconURL?: string;
    customerIconLink?: string;
    showUserLocation?: boolean;
    searchBarBehaviour?: SearchBarBehaviour;
    searchBarAutocompleters?: AutocompleterKeys[];
    // mapDescription?:string;
    
    mapDescription?:string;
    boundingBoxWhenSaved?: [[number,number],[number,number]], // WGS84 coordinates
};
    
export enum AppMode { Normal="Normal", ReadOnly="ReadOnly", Embedded="Embedded" }

export enum MapCommandType { 
    DisplayCadaster="DisplayCadaster",
    Unknown="Unknown"
}

export type MapCommand = {
    cmd:MapCommandType.DisplayCadaster,
    params: [string,string]
} | {
    cmd:MapCommandType.Unknown,
    params: []
}

export type UserSession = {
        id: string,
        featureSet?: any[],
        userName: string,
        name: string,
        email: string,
        userRoles: string[] ,
        authRoles?: string[] ,
        isAnonymousSession: boolean
        jwtToken: string|null,
        customerProfileCode?:string,
        customerProfileName?:string,
        deploymentMode: DeploymentMode
};

export type SessionInfo = {
    language?:string;
    appMode:AppMode;
    userSession?: UserSession;
    mapReferenceData?:{};
    commandList?: MapCommand[];
};

export type MapRestrictions = {
    maxZoom?: number,
    minZoom?: number,
    bounds ?: [[number,number],[number,number]],
    maxPitch?: number, 
    allowRotation?: boolean,
  }

// This is interface version 1.4
export type MapExportImportFormat_1_4 = {
        interfaceVersion:string;
        mapInfo:MapInfo;
        layers:LayerInfo[];
        userName:string;
        userId?: string;
        readOnly:boolean;
        timeGenerated:Date;   
        endDate?:Date;         
        securityKey?:string;     
        viewerState?: ViewerState;
        enabledFeatures?: any[]; // Only relevant for readOnly mode
        layerHierarchy?: LayerHierarchy;
        minZoom?: number;
        maxZoom?: number;
        mapRestrictions?: MapRestrictions
        activeFeatureLayerSpecs?:ActiveFeatureLayerSpecs;
    };

    // This is interface version 3.1
    export type MapExportImportFormat = {
        interfaceVersion:string;
        mapInfo:MapInfo;
        layers:LayerInfo[];
        userName:string;
        userId?: string;
        readOnly:boolean;
        timeGenerated:Date;   
        endDate?:Date;         
        securityKey?:string;     
        viewerState?: ViewerState;
        enabledFeatures?: any[]; // Only relevant for readOnly mode
        layerHierarchy?: LayerHierarchy;
        minZoom?: number;
        maxZoom?: number;
        mapRestrictions?: MapRestrictions
        activeFeatureLayerSpecs?:ActiveFeatureLayerSpecs;
        selectedBackgroundLayerKey?:string;
    };
    
    export type MapitImportFormat = MapExportImportFormat | MapitFormatCompressed
    export type Compressed = unknown
    export type MapitFormatCompressed = {
        interfaceVersion:string;
        mapInfo:MapInfo;
        zip_layers: Compressed;
        userName:string;
        userId?: string;
        readOnly:boolean;
        timeGenerated:Date;   
        endDate?:Date;         
        securityKey?:string;     
        viewerState?: ViewerState;
        enabledFeatures?: any[]; // Only relevant for readOnly mode
        layerHierarchy?: LayerHierarchy;
        minZoom?: number;
        maxZoom?: number;
        mapRestrictions?: MapRestrictions
        activeFeatureLayerSpecs?:ActiveFeatureLayerSpecs;
        selectedBackgroundLayerKey?:string;
    }

export type ActiveFeatureLayerSpecs = {[key:string]:ActiveFeatureLayer};
export type ActiveFeatureLayer = FeatureLayer["layer"]

export type ViewerState = {
    center:MitLatLng;
    zoomFactor?:number;
    zoom?:number;
    pitch?:number;
    bearing?:number;
};
    
export type ColorRangeList = ColorRange[];

export type ColorRange = {
    name: string;
    order: number;
    colors: ColorList;
    isCustom?: boolean;
};

export type ColorList = string[];

export type SizeRangeList = SizeRange[];

export type SizeRange = {
    name: string;
    order: number;
    sizes: number[];
};

export enum RowDataQuality {
    Good="4_RowDataQuality_Good",
    UserEdit="31_RowDataQuality_UserEdit",
    Warning="3_RowDataQuality_Warning",
    Bad="2_RowDataQuality_Bad",    // Row cannot be matched/used. Will not be shown on map.
    Error="1_RowDataQuality_Error"  // Reserved for system errors etc
}

export enum DawaQuality {   
                            unwashable="unwashable",
                            dawa_matched="dawa_matched",
                            fuzzy_washed="fuzzy_washed",
                            dawa_error="dawa_error",
                            dawa_washed="dawa_washed"
                        }

export type DawaAddressResponse = {
        query:string,
        result: DawaAddressResult
    };

export type DawaAddressResult = {
        quality: DawaQuality,
        distance: number, // Levenshtein distance for result 
        latlng?: number[], 
        washed?: string,
        errormessage?:string
    };

export type DawaAutoCompleteResult = {
    tekst:string, // the address
    data: {
        href:string, // HREF to lookup adress information
        id:string, // The id
        vejnavn:string,
        husnr:string,
        postnr:string,
        postnrnavn:string
    }
};
    
export type DawaAddressLookupResult = {
    adgangspunkt: {
        koordinater: number[],
    },
    ejerlav: {
        kode:number,
        navn:string
    },
    kommune: {
        kode:string,
        navn:string
    },
        region: {
        kode:string,
        navn:string
    },
    matrikelnr:string
};
    
export type POIResponse = {
    [poitype:string]: (POIResponseFound[] | POIResponseNone)
};

export type POIResponseFound = {
    "geometry": any, 
    "poilatlng": number[], 
    "name": string, 
    "poi_rep_id": number, 
    "euclideanmeters": number
};

export type POIResponseNone = {
    "fromlatlng": any,
    "status": string, // value is "none found"
    "totype": string
};

export type PredictionResult = {
        layerType:LayerType;
        weight:number;
        columnMapping:ColumnMapping;
        menuSelections?: any[];
    };
    
export type ReferenceGeomFeature = ReferenceGeomFeatureRegion | ReferenceGeomFeatureMunicipality | ReferenceGeomFeatureZipcodes | ReferenceGeomFeatureCountry 
| ReferenceGeomFeature_RO | ReferenceGeomFeature_DK_And_Schleswig | ReferenceGeomFeatureParish;

export type ReferenceGeomFeatureRegion = {
        type: string,
        properties: {
            REGIONKODE: string,
            REGIONNAVN: string
        },
        geometry: {
            type:string,
            coordinates: any[];
        }
    };

export type ReferenceGeomFeatureMunicipality = {
    type: string,
    properties: {
        KOMNAVN: string,
        Komnr: number
    },
    geometry: {
        type:string,
        coordinates: any[];
    }
};

export type ReferenceGeomFeatureZipcodes = {
    type: string,
    properties: {
        POSTBYNAVN: string,
        Postnummer: number
    },
    geometry: {
        type:string,
        coordinates: any[];
    }
};

export type ReferenceGeomType = {
    region: {
        type: string,
        crs: {
            type: string,
            properties: {},
        },
        features: ReferenceGeomFeatureRegion[]
    },
    municipality: {
        type: string,
        crs: {
            type: string,
            properties: {},
        },
        features: ReferenceGeomFeatureMunicipality[]
    },
    zipcodes: {
        type: string,
        crs: {
            type: string,
            properties: {},
        },
        features: ReferenceGeomFeatureZipcodes[]
    }
};

export type ReferenceGeomTypeWorld = {
    
        type: string,
        crs: {
            type: string,
            properties: {},
        },
        features: ReferenceGeomFeatureCountry[]
    
};

export type ReferenceGeomFeatureCountry = {
    type: string,
    properties: {
        admin: string,
        iso_a2: string
    },
    geometry: {
        type:string,
        coordinates: any[];
    }
};

export type ReferenceGeomType_RO = {
    
    type: string,
    crs: {
        type: string,
        properties: {},
    },
    features: ReferenceGeomFeature_RO[]

};

export type ReferenceGeomFeature_RO = {
type: string,
properties: {
    "ID_0": number, 
    "ISO": string, 
    "NAME_0": string, 
    "ID_1": 5, 
    "NAME_1": string, 
    "ID_2": number,
    "NAME_2": string, 
    "TYPE_2": string, 
    "ENGTYPE_2": string, 
    "NL_NAME_2": string, 
    "VARNAME_2": string 
},
geometry: {
    type:string,
    coordinates: any[];
}
};

export type ReferenceGeomType_DK_And_Schleswig = {
    
    type: string,
    crs: {
        type: string,
        properties: {},
    },
    features: ReferenceGeomFeature_DK_And_Schleswig[]

};

export type ReferenceGeomFeature_DK_And_Schleswig = {
type: string,
properties: {
    KOMNAVN: string,
    Komnr: number
},
geometry: {
    type:string,
    coordinates: any[];
}
};

export type ReferenceGeomTypeParish = {
    
    type: string,
    crs: {
        type: string,
        properties: {},
    },
    features: ReferenceGeomFeatureParish[]

};

export type ReferenceGeomFeatureParish = {
type: string,
properties: {
    "SOGNEID": number, 
    "SOGNENAVN": string
},
geometry: {
    type:string,
    coordinates: any[];
}
};

export enum DeploymentMode { 
    Unknown="Unknown", 
    Development="Development", 
    Test="Test", // Unit Test Enviromment
    Staging="Staging", 
    Testing="Testing",
    Demo="Demo", // Demo / Regression Test Environment
    Production="Production"
}

export type OtherLegendSpec = {
    renderLegend: () => React.ReactNode;
    showLegend: () => boolean;
};

/**
 * Either image path, glyphicon or icon text must be specified to include icon
 */
export type LabelButton = {
    function: (latlng: MitLatLng) => any,
    title: string,
    imageSrc?: string,
    iconText?: string,
    useButton?: boolean,
    stateAction?: {state:string, action: {type:any, payload?: any}},
    stateActionBase64?: any
};



// export type SystemSettingsData = {
//     "showLanguageSelection":boolean,
//     "defaultLanguage":string,
//     "showSearchOpenAsDefault":boolean,
//     "showTrialButtonBasic":boolean,
//     "showTrialButtonProfessional":boolean,
//     "addressServiceURL":string,
//     "addressServiceURLDawaWash":string,
//     "addressServiceURLDawaById":string,
//     "poiServiceURL":string,
//     "showEasyPrint":boolean,
//     "showLegend":boolean,
//     "unattendedImport":boolean,
//     "areaMapDefaultFillColor":string,
//     "areaMapColorForAreasWithNoData": string,
//     "areaMapColorForBorders": string,
//     "mapColorForOtherDataValue":string,
//     "areaMapHideAreasWithNoData": boolean,
//     "defaultMarkerSize":number,
//     "importMaxNumberOfRows":number,
//     "importMaxNumberOfGeoCodings":number,
//     "importMinNumberOfRows":number,
//     "importMaxNumberOfColumns":number,
//     "importMinNumberOfColumns":number,
//     "noOfRowsToTriggerDefaultClustering":number,
//     "noUnattendedImportOfAddresses": boolean,
//     "allowEditingOfDivisions":boolean,
//     "logsToCloudWatch": boolean,
//     "doLogsOfActions": boolean,
//     "showImportDetailsWindow": boolean,
//     "importDetailsWindowShowFailedOnly":boolean,
//     "saveDiagramInterfaceVersion": string,
//     "addressElementWashSeparator": string,
//     "POIsInUse": string[],
//     "95eb3b20-844c-4cab-9150-b56c36ed0525":string,
//     "shortLinks":boolean,
//     "restoreViewerStateOnLoadMap":boolean,
//     "exported-link-validity-days":number,
//     "enableImportOfGeoJSONFiles": boolean,
//     "createPointOnClickOnMap": boolean,
//     "detailedSelfEnrolmentProcess": boolean,
//     "labelsAsTabs":boolean,
//     "defaultLabelTabName":"ny tab",
//     "obliquePhotoEnabled": boolean,
//     "showLayerHideShowAllButton": boolean,
//     "useObliqueWebService": boolean,
//     "ambitionDataGroup":number,
//     "demographyReportLogo":string,
//     "demographyReportLanguageCode":string,
//     "targomoServiceKey":string,
//     "viamapToken": string,
//     "ambitionAuthHostProduction": string,
//     "ambitionAuthHostTest": string,
//     "ambitionApiHostProduction": string,
//     "ambitionApiHostTest": string,
//     "ambitionClientId": string,
//     "ambitionClientSecretProduction": string,
//     "ambitionClientSecretTest": string,
//     "mapitServicesVersion": string,
//     "mapitServicesHostProduction": string,
//     "mapitServicesHostStaging": string,
//     "mapitServicesAPIKey": string,
//     "ambitionVariableDocumentationLink": string,
//     "gotoAdressUseExistingPointRadiusMeters": number,
//     "geoJSONLineWeight": number,
//     "mapitGeoserverProxyResourceName": string,
//     "useDawaAddressWash": boolean,
//     "authDataStaging": {
//         "ClientId": string,
//         "AppWebDomain": string,
//         "TokenScopesArray": string[],
//         "RedirectUriSignIn": string,
//         "RedirectUriSignOut": string,
//         "UserPoolId": string,
//         "IdentityPoolId": string,
//         "PoolLoginKey": string,
//         "Region": string,
//         },
//     "authDataProduction": {
//         "ClientId": string,
//         "AppWebDomain": string,
//         "TokenScopesArray": string[],
//         "RedirectUriSignIn": string,
//         "RedirectUriSignOut": string,
//         "UserPoolId": string,
//         "IdentityPoolId": string,
//         "PoolLoginKey": string,
//         "Region": string,
//         }    
// };
    

import { useContext, useEffect, useReducer, useState } from 'react';
import './OwnershipDiagram.css';

import { Button } from 'react-bootstrap';
import { buildOwnershipStructure, NodeType, OwnerShipStructureData } from './OwnershipStructure';
import { PropertyInfoInterface as CompPropertyInfoInterface } from './PropertyInfoInterface';
import { PropertyInfoInterface } from '../managers/PropertyInfoInterface';
import { CountResult, GraphNode, GraphNodeData, OwnershipStructureTopDown } from './OwnershipStructureTopDown';
import { OwnershipGraph } from './OwnershipGraph';
import { ExploreSearchActions, ExploreSearchContext, ExploreSearchReducer, GenericTransactionManager, addFilter, initialExploreSearchState, removeFilter, transactionalExploreSearchReducer } from 'src/states/ExploreSearchState';
import { Results } from './Results';
import { MapitStateContext } from 'src/states/MapitState';
import { MitLatLng } from 'src/managers/MapFacade';
import { RightPaneContext, RightPaneModus, actionStepOut } from 'src/states/RightPaneState';
import { Localization, SettingsManager } from '@viamap/viamap2-common';
import { ApplicationStateContext } from 'src/states/ApplicationState';
import { SearchBarGeneral } from 'src/components/SearchBarGeneral';
import { CVRNumberAutocomplete, CVRNavnAutocomplete, CVRPersonAutocomplete } from 'src/managers/SearchBarGeneralPlugin';
import { ViewButtonCaret, ViewButtonText } from 'src/propertyInfoTemplates/PropertyComponents';
import { ADModalBody, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { useWindowContext } from 'src/WindowManager/useWindowContext';
import { closeWindow, openWindow, WindowId } from 'src/WindowManager/WindowState';
import { PiMouseLeftClickFill, PiMouseRightClickFill } from 'react-icons/pi';

export function OwnershipDiagram(props: { showWindow: number, search: {cvrNr: number} | {personInfo: cvrPerson} }) {
  const [state, dispatch] = useReducer(ExploreSearchReducer, {...initialExploreSearchState(), activeFilters:{}});
  const {dispatch: windowDispatch} = useWindowContext();

  const [graphKey, forceUpdateGraph] = useState(0)

  const {hasAccessToFeature} = useContext(ApplicationStateContext);
  
  let [ownership, setOwnership] = useState<OwnerShipStructureData>();
  let [graph, setGraph] = useState<GraphNode>();
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [cvrNr, setCvrNr] = useState<string>("");
  let [companyName, setCompanyName] = useState<string>();
  let [showResultsOnMap, setShowResultsOnMap] = useState<boolean>(false);
  let [counts, setCounts] = useState<CountResult>({ 
    virksomheder: 0, 
    ejendomme: 0,
    samletFastEjendom: 0,
    bygnFremmedGrund: 0,
    ejerlejlighed: 0,
 });

  useEffect(() => {
    if (props.search) {
      handleSubmit(props.search);
    }
  }, [props.search])

  function resetScreen() {
    forceUpdateGraph((a) => a+1)
    setIsLoading(false)
    setShowResultsOnMap(false)
    setOwnership(undefined);
    setGraph(undefined)
    setCounts({
      virksomheder: 0, 
      ejendomme: 0,
      samletFastEjendom: 0,
      bygnFremmedGrund: 0,
      ejerlejlighed: 0,
    })
  }

  async function handleSubmit(input: {cvrNr: number} | {personInfo: cvrPerson}) {
    setIsLoading(true);
    setCvrNr("" + cvrNr);
    // setShowResultsOnMap(false);
    dispatch(addFilter("bfe_nr_set", [-1])); // hide any points on map
    setCounts({ 
      virksomheder: 0, 
      ejendomme: 0,
      samletFastEjendom: 0,
      bygnFremmedGrund: 0,
      ejerlejlighed: 0,
    });
    setOwnership(undefined);
    if ("personInfo" in input) {
      constructTreeDataForPerson(input.personInfo).then(async (res: GraphNode) => {
        setGraph(res);
        setCompanyName(res.data.title);
        setCounts(res.data.countResult);
        let bfeSet = OwnershipStructureTopDown.extractBFENumbers(res);
        dispatch(addFilter("bfe_nr_set", bfeSet.length ? bfeSet : [-1]));
      }).catch((a) => {
        console.error("Got error " + a);
      }).finally(() => {
        setIsLoading(false);
        forceUpdateGraph((a) => a+1)
      })
    } else if ("cvrNr" in input) {
      constructTreeDataForCvr(input.cvrNr).then(async (res: GraphNode) => {
        setGraph(res);
        setCompanyName(res.data.title);
        setCounts(res.data.countResult);
        let bfeSet = OwnershipStructureTopDown.extractBFENumbers(res);
        dispatch(addFilter("bfe_nr_set", bfeSet.length ? bfeSet : [-1]));
      }).catch((a) => {
        console.error("Got error " + a);
      }).finally(() => {
        setIsLoading(false);
        forceUpdateGraph((a) => a+1)
      })
    }
  }

  if (!!!props.showWindow) {
    return <></>
  }

  const TestExampleButtons = () => {
    let exampleCVRS = SettingsManager.getSystemSetting("exploreOwnershipExampleCVRs", {});
    return (
      <>
      {Object.keys(exampleCVRS).map((key) => {
        let cvrnr=exampleCVRS[key];
        return (
          <Button key={key} onClick={async () => {
            handleSubmit(cvrnr)
          }}>
            {key}
          </Button>
        )
      })}
          </>
  );
  }

  if (!props.showWindow) {
    return <></>
  }

  return (
    <ExploreSearchContext.Provider value={{ state: state, dispatch: GenericTransactionManager.dispatchMiddleware<ExploreSearchActions>(dispatch, transactionalExploreSearchReducer) }}>
    <AdvancedDragModal
      PosDefault={{left:"100px", top:"50%", transform:"translateY(-50%)", height:"700px",width:"800px"}}
      PosDefaultNS={{width:"calc( 100% - 30px )", height: "calc(100% - 50px)", top: "50px", left:"15px"}}
      allowFullscreen
      allowUserMiniMize
      saveKey="OwnershipGraph"
      title={"Ejerskab"}
      onClose={(e) => windowDispatch(closeWindow(WindowId.OwnerShipDiagram))}
      subHeaderContent={
        

        <>
        <SearchBarGeneral
          
          showWindow={true}
          autocompleters={[
            new CVRNumberAutocomplete((data:any)=> {
              // Open Graph for cvr = data.cvrNummer
              handleSubmit({cvrNr: parseInt(data.cvrNummer)});
            }),
            new CVRNavnAutocomplete((data:any)=> {
              // Open Graph for cvr = data.cvrNummer
              handleSubmit({cvrNr: parseInt(data.cvrNummer)});
            }),
            new CVRPersonAutocomplete((data:any)=> {
              // alert("Clicked "+JSON.stringify(data));
              // Open PersonInfo for navn og cvr person enhedsnummer
              let gaeldendeNavn = data.navne.find((n:any) => { return n.periode.gyldigTil === null});
              let adr = data.deltagerpersonMetadata?.nyesteBeliggenhedsadresse;
              let postnr = adr?.postnummer;
              let adresse = adr?.vejnavn+ " "+adr?.husnummerFra; // Todo: bogstav og side...
              handleSubmit({personInfo: {cvrPersonId:data.enhedsNummer}});
            }),
          ]}
          callbackOnSelection = {(type:string, props:any) => { alert (`got callback Type:${type} props: ${JSON.stringify(props)}`)} }
          />
        {counts.ejendomme || showResultsOnMap ? (
          <ViewButtonCaret
          onClick={(e:React.MouseEvent)=>{setShowResultsOnMap(!showResultsOnMap)}}
          isOpen={showResultsOnMap}
          >
            Vis på kort
          </ViewButtonCaret>
          ) : null }
        <ViewButtonText onClick={() => resetScreen()} >{Localization.getText("Reset")}</ViewButtonText>
         </>
               }
               >
      <ADModalBody>
        {showResultsOnMap ? (
          <Results selectBFECallback={(a,b) => {
            windowDispatch(openWindow(WindowId.PropertyInformationDialog, {latlng: MitLatLng.fromM({lat:b[1], lng:b[0]})}));
          }} callBackOnClose={() => { dispatch(removeFilter("polygon"))}} showClose={false}  />
        ) : (
          <>
          <div style={{flex:1, display:"flex", overflow:"hidden", position:"relative"}}>
        <OwnershipGraph
          key={"graph-" + graphKey}
          graph={graph}
          callbackOnClickNode={(data:GraphNodeData) => {
            switch (data.type as NodeType) {
              case "Ejendom": 
              if (data.lat && data.lng) {
                windowDispatch(openWindow(WindowId.PropertyInformationDialog, {latlng: MitLatLng.fromM({lat:data.lat, lng:data.lng})}));
              }
              return
              case "Person":
                if ("text" in data && data.entityId) {
                  windowDispatch(openWindow(WindowId.PersonInformationDialog, {navn: data.text, cvrPersonId: data.entityId}));
                }  
              return
              case "Firma":
              case "Aktieselskab":
                setCvrNr("" + data.entityId);
                // handleSubmit(parseInt("" + data.entityId))
                windowDispatch(openWindow(WindowId.CompanyInformationDialog, {cvrNr:data.entityId}));
              return
              case "Link":
                console.warn("Callback from link")
              return
            }
          }}
          callbackOnRClickNode={(data:GraphNodeData) => {
            switch (data.type) {
              case "Person":
                handleSubmit({personInfo:{cvrPersonId: data.entityId, navn: data.title}});
              return
              case "Firma":
              case "Aktieselskab":
                handleSubmit({cvrNr: data.entityId});
              return
              case "Ejendom": 
              case "Link":
                console.warn("Callback from link");
              return
            }
          }} />
          { !!companyName ?
            <>
            <div className='OwnershipInfo'>
            <b>{companyName} ({cvrNr})</b>
            <br/>{counts.virksomheder} Virksomheder, {counts.ejendomme} Ejendomme
            {counts.bygnFremmedGrund + counts.ejerlejlighed > 0 ? (
              <>(SFE:{counts.samletFastEjendom}, BFG:{counts.bygnFremmedGrund}, Ejerl:{counts.ejerlejlighed})</>
            ) : null}
            </div>
            </>
          : null }
            <div className='OwnershipHelp'>
            <div><PiMouseLeftClickFill /> {Localization.getText("ownership:Information")}</div>
            <div><PiMouseRightClickFill /> {Localization.getText("ownership:Explore")}</div>
            </div>
          </div>
          </>
        )}

      </ADModalBody>
    </AdvancedDragModal>
          
    </ExploreSearchContext.Provider>
  );
}

export default OwnershipDiagram;



// setCounts({ 
//   virksomheder: 0, 
//   ejendomme: 0,
//   samletFastEjendom: 0,
//   bygnFremmedGrund: 0,
//   ejerlejlighed: 0,
// });

type cvrNr = number;
type cvrPerson = {cvrPersonId: number} | {navn:string, postnr:number, adresse:string, vejnavn?:string, husnr?:string}

async function constructTreeDataForPerson(personInfo: cvrPerson) {
  if (("cvrPersonId" in personInfo) || (personInfo.navn && personInfo.postnr && personInfo.adresse)) {
    function getActiveRecord(recs: { periode: { gyldigFra: string, gyldigTil: string } }[]): any {
      return recs.find((r) => r.periode.gyldigTil === null);
    }  
    function aktiveVirksomheder(virksomhedSummariskRelation:any):boolean {
      return virksomhedSummariskRelation?.virksomhed?.livsforloeb && getActiveRecord(virksomhedSummariskRelation.virksomhed.livsforloeb);
    }
    function aktiveFunktioner(organisationer:any):boolean {
      let funktionAttr = organisationer.attributter?.find((org) => org.type === "FUNKTION");
      return funktionAttr?.vaerdier && getActiveRecord(funktionAttr.vaerdier);
    }
    let cvrInfo;
    if ("cvrPersonId" in personInfo && personInfo.cvrPersonId) {
      cvrInfo = await PropertyInfoInterface.CVRPersonDeltagerByEnhedsNummer(personInfo.cvrPersonId);
    } else if ("navn" in personInfo && personInfo.navn) {
      let re = new RegExp(/([^0-9]*)([0-9]+[a-zA-Z]*)/);
      let res = re.exec(personInfo.adresse);
      let vejnavn = (personInfo.vejnavn ?? res?.[1]) || "";
      let husnr = Number((personInfo.husnr ?? res?.[2]) || "1");
      
      cvrInfo = await PropertyInfoInterface.CVRPersonDeltagerByNameAndAddress(personInfo.navn, vejnavn, husnr, personInfo.postnr, "DK");
    }
    let res = cvrInfo.virksomhedSummariskRelation?.filter(aktiveVirksomheder).map((vr) => {
        let virkData = {
          cvrNummer: vr.virksomhed.cvrNummer,
          navn : getActiveRecord(vr.virksomhed.navne)?.navn || "ukendt",
        };
        let orgsData = vr.organisationer.filter(aktiveFunktioner).map((org) => {
          return {
            hovedType : org.hovedtype,
            navn: getActiveRecord(org.organisationsNavn)?.navn || "ukendt"
          }
        });
        return {
          virkData, orgsData
        }
    });
    let EjendomsListe = []
    if (cvrInfo.navne?.[0]?.navn) {
      let BelAdr = cvrInfo.deltagerpersonMetadata.nyesteBeliggenhedsadresse
      let adresse = BelAdr.vejnavn + " " + BelAdr.husnummerFra

      let liste = await PropertyInfoInterface.getPropertiesOwnedByPerson(cvrInfo.navne?.[0]?.navn, BelAdr.postnummer, adresse);
      EjendomsListe = (liste?.hits?.hits.map((ht) => ht._source) || []);
    }
      if (cvrInfo) {
        let x:GraphNode = {data: {
          type:"Person" as NodeType,
          title: cvrInfo.navne?.[0]?.navn || personInfo["navn"],
          entityId:cvrInfo.enhedsNummer || -1, expandedAsDefault:true, isCollapsed:true, pct:0,
          lat:0, lng:0, ejendomsType:"",countResult: {virksomheder: 0, 
            ejendomme: EjendomsListe.length,
            samletFastEjendom: 0,
            bygnFremmedGrund: 0,
            ejerlejlighed: 0}
        }, children: [...await Promise.all(res.map(async (a) => {
          let res = await constructTreeDataForCvr(a.virkData.cvrNummer)
          let chld = res.children.filter((a) => {
            return a.data?.dir == undefined
          })
          let owner = res.children.find((a) => {
            return a.data.title == cvrInfo.navne?.[0]?.navn
          })
          let y = {data:{...res.data, pct: owner?.data?.pct || 0, dir: owner?.data?.pct ? undefined : "UP",
          reverse:  owner?.data?.pct ? undefined : true,
          "linkNote":a.orgsData.filter((rolle) => ["LEDELSESORGAN","FULDT_ANSVARLIG_DELTAGERE"].includes(rolle.hovedType)).map((rolle) => rolle.navn).join(", ") || undefined,
          countResult: owner?.data?.pct ? res.data.countResult : {virksomheder: 0, 
            ejendomme: 0,
            samletFastEjendom: 0,
            bygnFremmedGrund: 0,
            ejerlejlighed: 0}
        },children: owner?.data?.pct ? chld : []}
              
          return y
        })), ...EjendomsListe.map((a:any) => {

          let owner = a.ejf_ejere_liste.find((a) => {
            return a.navn == cvrInfo.navne?.[0]?.navn
          })
          let x = {
            children: [],
            data: {type:"Ejendom" as NodeType,
              title:a.bfe_adresse || "Ukendt Adresse",
              entityId:a.bfe_nr, expandedAsDefault:true, isCollapsed:true, pct: owner ? owner.andelProcent: -1,
              lat: a.koord_lat, lng:a.koord_lng, ejendomsType:a.bfe_ejendomsType,countResult: OwnershipStructureTopDown.constructCount(a),
            }
          }
          x.data.countResult =  OwnershipStructureTopDown.constructCount(x.data)
          return x
          })]
        }
        let [visited, count] = RecursivCount(x) 
        x.data.countResult = count
        return x
      }
    }
    return {data: {
      type:"Person",
      title: "Unknown Person",
      entityId:0, expandedAsDefault:true, isCollapsed:true, pct:0,
      lat:0, lng:0, ejendomsType:"",countResult: {virksomheder: 0, 
        ejendomme: 0,
        samletFastEjendom: 0,
        bygnFremmedGrund: 0,
        ejerlejlighed: 0}
    }, children: []} as GraphNode
  }

async function constructTreeDataForCvr(cvrNr: cvrNr) {

    let cvrData = await CompPropertyInfoInterface.getCVRData(cvrNr);
    let keyData = CompPropertyInfoInterface.extractRelevantData(cvrData);
    let inst = new OwnershipStructureTopDown();
    const down = await inst.createTree({ entityId: cvrNr, title: keyData.navn, type: "Aktieselskab", expandedAsDefault:true, isCollapsed: true, pct:0, lat:0, lng:0,ejendomsType:"",countResult: {virksomheder: 0, 
      ejendomme: 0,
      samletFastEjendom: 0,
      bygnFremmedGrund: 0,
      ejerlejlighed: 0}})
      .then(async (res: any) => {
        inst.checkTree(res);
        return res
      })
      .catch((error3: any) => {
        console.error("Got error " + error3);
      })

    const up = await buildOwnershipStructure("Firma", "Dummy", cvrNr)
      .then((result3: any) => {
        return result3
      })
      .catch((error3: any) => {
        console.error("Got error " + error3);
      })

    // return {
    //   up: up,
    //   down: down
    // }

    let x = {...down, children: [...down.children, ...up.children.map((a) => {a.data.dir = "UP"; return a})]}

    return x
}

function RecursivCount(x:GraphNode, visited:number[] = []) : [number[], typeof x["data"]["countResult"]] {
  if (visited.includes(x.data.entityId) || x.data["dir"]) {
    return [visited,{
      virksomheder: 0, 
      ejendomme: 0,
      samletFastEjendom: 0,
      bygnFremmedGrund: 0,
      ejerlejlighed: 0
    }]
  } 

  let nVisited = [...visited, x.data.entityId];
  let resCount = x.children.reduce((pre, next) => {
    let [rVisited, rCount] = RecursivCount(next, nVisited)
    nVisited = rVisited;
    let nCount = {};
    (Object.keys(rCount).forEach((a) => {
      nCount[a] = pre[a] + (rCount[a] ?? 0)
    }))
    return nCount as typeof x["data"]["countResult"]
  }, OwnershipStructureTopDown.constructCount(x.data))


  return [nVisited,resCount]
}
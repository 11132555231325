import { SearchBarGeneralContext, SearchBarGeneralContextType, SearchBarGeneralPlugin, SearchBarGeneralPluginSuggestion, SuggestionError, SuggestionsGroup } from "src/components/SearchBarGeneral";
import { PropertyInfoInterface } from "./PropertyInfoInterface";
import { PropertyInfoInterface as PropertyInfoInterfaceCVR } from '../compentsModus/PropertyInfoInterface';
import { MapitUtils } from "./MapitUtils";
import { Localization, SettingsManager } from "@viamap/viamap2-common";
import { ESInterface } from "src/compentsModus/ESInterface";
import { FilterType } from "src/states/ExploreSearchState";
import { ToBeLocalizedString } from "@viamap/viamap2-common/dist/managers/Types";
import { FeatureLayer } from "./WmsLayerFunc";
import { CustomGroup } from "src/components/GroupedLayerControl";
import { LayerInfo } from "src/common/managers/Types";
import { GenerateGeomUtils } from "./GenerateGeomUtils";

/** Interface for autocomplete search plugins */
export abstract class AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
    protected key;
    protected title;
    protected minLength;
    protected maxLength;
    protected icon;
    protected maxSuggestionCount;
    public onSelection: undefined | ((data:any, searchText?:string) => void);

    /**
     * 
     * @param key Unique string identifying this Autocompleter½
     * @param title Title to be translated
     * @param icon Icon name to use
     * @param minLength Minimum size of input string when this autocompleter is used
     * @param maxLength Maximum size of input string when this autocompleter is used
     * @param maxSuggestionCount Maximum suggestions to return
     * @param onSelection Callback when a selection is done
     */
    constructor(key:string, title:ToBeLocalizedString, minLength:number, maxLength:number, maxSuggestionCount:number, icon:string, onSelection?:(data:any, searchText?:string) => void) {
        this.title = title;
        this.minLength = minLength;
        this.maxLength = maxLength;
        this.icon = icon;
        this.maxSuggestionCount = maxSuggestionCount;
        this.onSelection = onSelection;
        this.key = key;
    }

    getKey() {
        return this.key;
    }

    getTitle() {
        return this.title;
    }

    getIcon() {
        return this.icon;
    }

    getContextType(): SearchBarGeneralContextType | undefined {
        return this.key;
    }

    getSuggestions(context: SearchBarGeneralContext, queryString:string, sequenceNumber: number):Promise<SuggestionsGroup|SuggestionError> {
        let result:SearchBarGeneralPluginSuggestion[]=[];

        return new Promise<SuggestionsGroup|SuggestionError>(async(resolve, reject) => {
            if (this.shouldBeActive(context, queryString)) {
                try {
                    let x = await this.calculateSuggestions(context, queryString)
                    if ("error" in x) {
                        resolve({error: `${x.error}`})
                    } else {
                        result = x;
                    }
                } catch (err: any) {
                    resolve({error: err?.msg || err})
                }
            }
            resolve({title: this.getTitle(),
                key: this.key,
                sequenceNumber: sequenceNumber,
                suggestions: result});
        })  
    }

    shouldBeActive(context: SearchBarGeneralContext, queryString:string):boolean
    {
        if (Object.keys(context).includes(this.getKey()) || (! Object.keys(context).length))  {
            let length = queryString.trim().length
            return length >= this.minLength && length <= this.maxLength;
        }
        return false
    }


    abstract calculateSuggestions(context: SearchBarGeneralContext, queryString:string):Promise<SearchBarGeneralPluginSuggestion[]|SuggestionError>;

}

// ---------------------------------------------------------------------------------------------------------------------------------------

export class EjerlavAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {

    constructor(onSelection?:(data:any) => void) {
        super("Ejerlavskode", {key:"Cadastral Region"}, 3, 30, 100, "ejerlav", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        let newEjerlavSuggestions = await PropertyInfoInterface.ejerlavAutocomplete(queryString);
        if (newEjerlavSuggestions.map) {
            return newEjerlavSuggestions.map(suggestion => {
                return {
                    betegnelse: suggestion.betegnelse,
                    isContext: true,
                    contextType: "Matrikel",
                    contextParse: `$, ${suggestion.betegnelse}`,
                    code: suggestion.kode,
                    name: suggestion.navn,
                    data: suggestion,
                    onSelection: this.onSelection
                };
            });
        } else {
            return [];
        }
    }
}

export class MatrikelAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
    constructor(onSelection:(data:any) => void) {
        super("Matrikel", {key:"Cadaster"}, 1, 50, 100, "matrikel", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        let ejerlavsKode = context["Ejerlavskode"];
        let filter = ejerlavsKode ? `ejerlavskode=%27${ejerlavsKode.code}%27` : undefined;
        let suggestionList = await PropertyInfoInterface.gSearch('matrikel', queryString, this.maxSuggestionCount, filter)
        if (suggestionList) {
            let result = suggestionList.sort((a, b) => {
                if (typeof a.matrikelnummer !== "string") { a.matrikelnummer = ""; }
                if (typeof b.matrikelnummer !== "string") { b.matrikelnummer = ""; }
        
                let res = MapitUtils.compareCadasterOrder(a.matrikelnummer, b.matrikelnummer);
                return res;
            }).map(suggestion => {
                return {
                    betegnelse: suggestion.visningstekst,
                    isContext: false,
                    code: suggestion.jordstykke_id,
                    name: suggestion.visningstekst,
                    data: suggestion,
                    onSelection: this.onSelection
                };
            });
            return result;
        } else {
            return [];
        }
    }

}

export class VejAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {

    constructor(onSelection?:(data:any) => void) {
        super("Navngivenvej", {key:"Street name"}, 3, 50, 100, "vej", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        // NOT USED AS getSuggestions() is owerridden (below)
        return [];
    }

    getSuggestions(context: SearchBarGeneralContext, queryString:string, sequenceNumber: number) {
        return new Promise<SuggestionsGroup|SuggestionError>(async(resolve, reject) => {
            const token = SettingsManager.getSystemSetting("dataforsyningenToken", "");
            if (this.shouldBeActive(context, queryString)) {
                PropertyInfoInterface.gSearch('navngivenvej', queryString, this.maxSuggestionCount)
                .then((json) => {
                    let result:SearchBarGeneralPluginSuggestion[] = json.map((item) => {
                        return {
                            betegnelse: `${item.vejnavn}, ${item.postnummer} ${item.postnummernavn}`,
                            isContext: true,
                            contextParse: `${item.vejnavn} $, ${item.postnummer} ${item.postnummernavn}`,
                            contextType: "Address",
                            code: item.id,
                            name: item.visningstekst,
                            data: item,
                            onSelection: this.onSelection
                        }
                    });
                    resolve({title: this.getTitle(),
                        key: this.key,
                        sequenceNumber: sequenceNumber,
                        suggestions: result});
                })
                .catch((err) => {
                    resolve({error:`Error getting adresses ${err}`});
                })
            } else {
                resolve({title: this.getTitle(),
                    key: this.key,
                    sequenceNumber: sequenceNumber,
                    suggestions: []});
            }
        })        
    }
}

export class StednavnAutoComplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
    constructor(onSelection?:(data:any) => void) {
        super("Stednavn", {key:"Place name"}, 3, 50, 100, "stednavn", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        let filter = undefined;
        try {
            let suggestionList = await PropertyInfoInterface.gSearch('stednavn', queryString, this.maxSuggestionCount, filter);
            if (suggestionList) {
                let result = suggestionList.map(suggestion => {
                    return {
                        betegnelse: suggestion.visningstekst,
                        isContext: false,
                        code: suggestion.id,
                        name: suggestion.visningstekst,
                        data: suggestion,
                        onSelection: this.onSelection
                    };
                });
                return result;
            } else {
                return [];
            }
        } catch (err: any) {
            return {error: `${err?.msg || err}`}
        }
    }
}

export class PostNRAutoComplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
    constructor(onSelection?:(data:any) => void) {
        super("Postnummer", {key:"Postal"}, 4, 6, 100, "postnr", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        let filter = undefined;
        try {
            let suggestionList = await PropertyInfoInterface.gSearch('postnummer', queryString, this.maxSuggestionCount, filter);
            if (suggestionList) {
                let result = suggestionList.map(suggestion => {
                    return {
                        betegnelse: suggestion.visningstekst,
                        isContext: false,
                        code: suggestion.id,
                        name: suggestion.visningstekst,
                        data: suggestion,
                        onSelection: this.onSelection
                    };
                });
                return result;
            } else {
                return [];
            }
        } catch (err: any) {
            return {error: `${err?.msg || err}`}
        }
    }
}

export class AddressAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {

    constructor(onSelection:(data:any) => void) {
        super("Address", {key:"Address"}, 5, 50, 100, "hus", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        // NOT USED AS getSuggestions() is owerridden (below)
            return [];
    }

    getSuggestions(context: SearchBarGeneralContext, queryString:string, sequenceNumber: number) {
        return new Promise<SuggestionsGroup|SuggestionError>(async(resolve, reject) => {
            const token = SettingsManager.getSystemSetting("dataforsyningenToken", "");
            let vejnavnCtx = context["Navngivenvej"];
            let filter = vejnavnCtx ? `vejnavn=%27${vejnavnCtx.data.vejnavn}%27 AND postnummer='${vejnavnCtx.data.postnummer}'` : undefined;
            if (this.shouldBeActive(context, queryString)) {
                PropertyInfoInterface.gSearch('adresse', queryString, this.maxSuggestionCount, filter)
                .then((json) => {
                    let result:SearchBarGeneralPluginSuggestion[] = json.map((item) => {
                        return {
                            betegnelse: item.visningstekst,
                            isContext: false,
                            code: item.id,
                            name: item.visningstekst,
                            data: item,
                            onSelection: this.onSelection
                        }
                    });
                    resolve({title: this.getTitle(),
                        key: this.key,
                        
                        sequenceNumber: sequenceNumber,
                        suggestions: result});
                })
                .catch((err) => {
                    resolve({error: `${err?.msg || err}`});
                })
            } else {
                resolve({title: this.getTitle(),
                    key: this.key,
                    sequenceNumber: sequenceNumber,
                    
                    suggestions: []});
            }
        })        
    }
}

export class AddressBoundingBoxAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {

    boundingBox?:[[number, number],[number,number]] | undefined;

    constructor(onSelection:(data:any) => void, boundingBox?:[[number, number],[number,number]] | undefined) {
        super("LocalAddress", {key:"LocalAddress"}, 5, 50, 100, "hus", onSelection);
        this.boundingBox = boundingBox;
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        // NOT USED AS getSuggestions() is owerridden (below)
            return [];
    }

    getSuggestions(context: SearchBarGeneralContext, queryString:string, sequenceNumber: number) {
        return new Promise<SuggestionsGroup|SuggestionError>(async(resolve, reject) => {
            const token = SettingsManager.getSystemSetting("dataforsyningenToken", "");
            let vejnavnCtx = context["Navngivenvej"];
            let filter = vejnavnCtx ? `vejnavn=%27${vejnavnCtx.data.vejnavn}%27 AND postnummer='${vejnavnCtx.data.postnummer}'` : undefined;
            if (this.boundingBox) {
                let sW = GenerateGeomUtils.convertFromLatLngToUTM({lat:this.boundingBox[0][1], lng:this.boundingBox[0][0]});
                let nE = GenerateGeomUtils.convertFromLatLngToUTM({lat:this.boundingBox[1][1], lng:this.boundingBox[1][0]});
                filter = (filter || "") + `INTERSECTS(vejpunkt_geometri,POLYGON((${sW.x} ${sW.y}, ${nE.x} ${sW.y}, ${nE.x} ${nE.y}, ${sW.x} ${nE.y}, ${sW.x} ${sW.y})))`
            }  
            if (this.shouldBeActive(context, queryString)) {
                PropertyInfoInterface.gSearchWithUTM32Filter('adresse', queryString, this.maxSuggestionCount, filter)
                .then((json) => {
                    let result:SearchBarGeneralPluginSuggestion[] = json.map((item) => {
                        return {
                            betegnelse: item.visningstekst,
                            isContext: false,
                            code: item.id,
                            name: item.visningstekst,
                            data: item,
                            onSelection: this.onSelection
                        }
                    });
                    resolve({title: this.getTitle(),
                        key: this.key,
                        
                        sequenceNumber: sequenceNumber,
                        suggestions: result});
                })
                .catch((err) => {
                    resolve({error: `${err?.msg || err}`});
                })
            } else {
                resolve({title: this.getTitle(),
                    key: this.key,
                    sequenceNumber: sequenceNumber,
                    
                    suggestions: []});
            }
        })        
    }
}

export class CVRNumberAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {

    constructor(onSelection:(data:any) => void) {
        super("CVRnr", {key:"CVR no"}, 6, 9, 100, "virksomhed", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        let number = Number.parseInt(queryString);
        if (!number) {
            return [];
        }
        let decimals = number.toString().length;
        let targetDecimals=9;
        let min = number;
        let max = decimals < targetDecimals ? (number+1) * Math.pow(10, targetDecimals-decimals) : number;

        let suggestionList = await PropertyInfoInterfaceCVR.getCVRSearch({
            "_source": [
                "Vrvirksomhed.cvrNummer",
                "Vrvirksomhed.virksomhedMetadata",
                "cvr_string",
            ],
            "query": {
                "bool": {
                    "must": [
                        {
                            "fuzzy": { "Vrvirksomhed.cvrNummer": { value: queryString, prefix_length: 6 } }
                        },
                        {
                            "bool": {
                                "should": [
                                    {
                                        "match": { "Vrvirksomhed.virksomhedMetadata.sammensatStatus": "Aktiv" }
                                    },
                                    {
                                        "match": { "Vrvirksomhed.virksomhedMetadata.sammensatStatus": "NORMAL" }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        });
        if (suggestionList?.hits?.total > 0) {
            return suggestionList?.hits?.hits?.map((hit) => {
                let item = hit?._source?.Vrvirksomhed;
                let branche = item?.virksomhedMetadata?.nyesteHovedbranche?.branchetekst;
                return({
                    betegnelse: `${item.virksomhedMetadata.nyesteNavn.navn} (${item.cvrNummer}) ${branche}`,
                    isContext: false,
                    code: item.cvrNummer,
                    name: item.virksomhedMetadata.nyesteNavn.navn,
                    data: item,
                    onSelection: this.onSelection
                })
            });
        } else {
            return [];
        }
    }
}

export class CVRNavnAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {

    constructor(onSelection:(data:any) => void) {
        super("Virksomhed", {key:"Company"}, 4, 50, 100, "virksomhed", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        let suggestionList = await PropertyInfoInterfaceCVR.getCVRSearch({
            "_source": [
               "Vrvirksomhed.cvrNummer",
               "Vrvirksomhed.virksomhedMetadata",
            ],
            "query": {
                "bool": {
                    "must": [
                        {
                            "match": { "Vrvirksomhed.virksomhedMetadata.nyesteNavn.navn": queryString }
                        },
                        {
                            "bool": {
                                "should": [
                                    {
                                        "match": { "Vrvirksomhed.virksomhedMetadata.sammensatStatus": "Aktiv" }
                                    },
                                    {
                                        "match": { "Vrvirksomhed.virksomhedMetadata.sammensatStatus": "NORMAL" }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        });
        if (suggestionList?.hits?.total > 0) {
            return suggestionList?.hits?.hits?.map((hit) => {
                let item = hit?._source?.Vrvirksomhed;
                let branche = item?.virksomhedMetadata?.nyesteHovedbranche?.branchetekst;
                return({
                    betegnelse: `${item.virksomhedMetadata.nyesteNavn.navn} (${item.cvrNummer}) ${branche}`,
                    isContext: false,
                    code: item.cvrNummer,
                    name: item.virksomhedMetadata.nyesteNavn.navn,
                    data: item,
                    onSelection: this.onSelection
                })
            });
        } else {
            return [];
        }
    }
}

export class CVRPersonAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {

    constructor(onSelection:(data:any) => void) {
        super("cvrPrson", {key:"CVR Person"}, 6, 50, 100, "deltager", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        let suggestionList = await PropertyInfoInterfaceCVR.getCVRSearch({
            "_source": [
               "Vrdeltagerperson"
            ],
            "query": {
                "bool": {
                    "must": [
                        {
                            "match": {
                                "Vrdeltagerperson.enhedstype": "PERSON"
                            }
                        },
                        {
                            "match": {
                                "Vrdeltagerperson.navne.navn": queryString
                            }
                        }
                    ],
                    "must_not": [
                        {
                            "exists": {
                                "field": "Vrdeltagerperson.navne.periode.gyldigTil"
                            }
                        }
                    ]
                }
            }
        }, "deltager");

        if (suggestionList?.hits?.total > 0) {
            return suggestionList?.hits?.hits?.map((hit) => {

                let item = hit?._source?.Vrdeltagerperson;
                let gaeldendeNavn = item.navne.find((n:any) => { return n.periode.gyldigTil === null});
                let adresse = item.deltagerpersonMetadata?.nyesteBeliggenhedsadresse && PropertyInfoInterfaceCVR.formatAddress(item.deltagerpersonMetadata.nyesteBeliggenhedsadresse);
                return({
                    betegnelse: `${gaeldendeNavn.navn+(adresse? ", "+adresse:"")}`,
                    isContext: false,
                    code: item.enhedsNummer,
                    name: gaeldendeNavn.navn,
                    data: item,
                    onSelection: this.onSelection
                })
            });
        } else {
            return [];
        }
    }
}

export class EjerNavnAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {

    constructor(onSelection:(data:any, searchText?:string) => void) {
        super("Ejernavn", {key:"Owner name"}, 4, 50, 100, "person", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        
        async function getOwnersByFilter(prefix:string, maxCount): Promise<any[]> {
            const elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");
            let ifx = new ESInterface(elasticIndex);
            let tmpFilters =   {"ejf_ejere": { title: "Owner", filterType:FilterType.String, default:[""],
            translationTable: {"en":{"Owner":"Owner"},"da":{"Owner":"Ejernavn"}}}};
            let tmpFiltersValues = { "ejf_ejere": [prefix] };
            let query = ifx.makeQuery(tmpFilters, tmpFiltersValues);
            query = {
                ...query,
                "size": 0,
                "aggs": {
                    "ejer_unique": {
                        "terms": {
                            "field": "ejf_ejere.keyword",
                            "size": maxCount
                        },
                        "aggs": {
                            "property_record": {
                                "top_hits": {
                                    "size": 1
                                }
                            }
                        }
                        }
                }
            };
            let items = await ifx.doQuery(query);
            let names = items?.aggregations?.ejer_unique?.buckets;
            return names || [];
         }

         let options= await getOwnersByFilter(queryString, this.maxSuggestionCount);
         if (options) {
             let result = options.map((item) => {
                 return {
                     betegnelse: `${item.key} (${item.doc_count})`,
                     isContext: false,
                     code: item.key,
                     name: item.key,
                     data: item,
                     onSelection: this.onSelection
                 }
                 });
                 return result;
         } else {
            return [];
        }
    }

}

export class BFEAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {

    constructor(onSelection:(data:any) => void) {
        super("BFE", {key:"BFE"}, 6, 8, 100, "hus", onSelection);
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
        
        async function getOwnersByFilter(prefix:string, maxCount:number): Promise<any[]> {
            let number = Number.parseInt(queryString);
            if (!number) {
                return [];
            }
            let decimals = number.toString().length;
            let targetDecimals=7;
            let min = number;
            let max = decimals < targetDecimals ? (number+1) * Math.pow(10, targetDecimals-decimals) : number;

            const elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");
            let ifx = new ESInterface(elasticIndex);
            let tmpFilters =   {"bfe_nr": { title: "BFE number", filterType:FilterType.IntegerRange, default:[""],
            translationTable: {"en":{"BFE nummer":"BFE number"},"da":{"BFE nummer":"BFE nummer"}}}};
            let tmpFiltersValues = { "bfe_nr": [min, max] };
            let query = ifx.makeQuery(tmpFilters, tmpFiltersValues);
            query = {
                ...query, 
                "size":maxCount
            };
            let items = await ifx.doQuery(query);
            let names = items?.hits?.hits;
            return names || [];
         }

         let options= await getOwnersByFilter(queryString, this.maxSuggestionCount);
         if (options) {
             let result = options.map((it) => {
                let item = it._source;
                 return {
                     betegnelse: `${item.bfe_adresse} (${item.bfe_nr}, ${item.bfe_ejendomsType})`,
                     isContext: false,
                     code: item.bfe_nr,
                     name: item.bfe_adresse,
                     data: item,
                     onSelection: this.onSelection
                 }
                 });
                 return result;
         } else {
            return [];
        }
    }

}

export class FavoriteLayerAutoComplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
    private searchString:string = ''
    private fvl:CustomGroup[] = []

    constructor(fvl:CustomGroup[], onSelectin: (data) => void) {
        super("Favoritlag", {key:"Favorite layers"}, 3, 20, 100, "layer", onSelectin)
        this.searchString = fvl.map((a) => {
            return `"(${a.uuid}) ${a.title}"`
        }).join("")
        this.fvl = fvl
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString: string): Promise<SearchBarGeneralPluginSuggestion[]> {
        if (queryString.includes("\"")) {
            return []
        }
        const rx = new RegExp('"([^"]*'+queryString+'[^"]*)"','gi');
        let i = 0;
        let results:SearchBarGeneralPluginSuggestion[] = [];
        let x 
        while ((x = rx.exec(this.searchString)) !== null) {
            const label = x[1].split("\)")[0].split("(")[1] as string
            let fvl = this.fvl.find((a) => a.uuid == label) || this.fvl[0] 
            results.push({
                betegnelse: fvl.title,
                code: label,
                name: label,
                data: fvl,
                onSelection: this.onSelection
            })
        }
        return results
    }
}

export class FeatureLayerAutoComplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
    private searchString:string = ''
    private ftl:FeatureLayer[] = []
    activeKeys: string[];

    constructor(ftl:FeatureLayer[], activeKeys: string[], onSelectin: (data) => void) {
        super("Lag", {key:"Layers"}, 2, 20, 100, "layer", onSelectin)
        this.searchString = ftl.map((a) => {
            let search = `${a.layer.group?.replace(".",":")||""}:${a.layer.translationTable?.[Localization.getLanguage()]?.[a.layer.label] ?? a.layer.label}`
            return [`[{(${a.layer.label})}] ${search}`, `[{(${a.layer.label})}] ${search.replaceAll(" ","")}`]
        }).flat().join('""')
        this.ftl = ftl
        this.activeKeys = activeKeys
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString: string): Promise<SearchBarGeneralPluginSuggestion[]> {
        if (queryString.includes("\"")) {
            return []
        }
        const rx = new RegExp('"([^"]*'+queryString+'[^"]*)"','gi');
        let i = 0;
        let results:SearchBarGeneralPluginSuggestion[] = [];
        let labels:string[] = [];
        let x 
        while ((x = rx.exec(this.searchString)) !== null) {
            const label = x[1].split("\)\}\]")[0].split("\[\{\(")[1] as string
            labels.push(label)
        }
        [...new Set(labels)].map((label) => {
            let ftl = this.ftl.find((a) => a.layer.label == label) || this.ftl[0] 
            results.push({
                betegnelse: ftl.layer.translationTable?.[Localization.getLanguage()]?.[ftl.layer.label] ?? ftl.layer.label,
                code: label,
                name: label,
                checked: this.activeKeys.includes(label),
                data: ftl,
                onSelection: this.onSelection
            })
        })
        return results
    }
}

export class CustomLayerAutoComplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
    private searchString:string = ''
    private ftl:LayerInfo[] = []

    constructor(ftl:LayerInfo[], onSelectin: (data) => void) {
        super("Speciallag", {key:"Special layers"}, 3, 20, 100, "slayer", onSelectin)
        this.searchString = ftl.map((a) => {
            return `"(${a.layerId}) ${a.datasetname}"`
        }).join("")
        this.ftl = ftl
    }

    async calculateSuggestions(context: SearchBarGeneralContext, queryString: string): Promise<SearchBarGeneralPluginSuggestion[]> {
        if (queryString.includes("\"")) {
            return []
        }
        const rx = new RegExp('"([^"]*'+queryString+'[^"]*)"','gi');
        let i = 0;
        let results:SearchBarGeneralPluginSuggestion[] = [];
        let x 
        while ((x = rx.exec(this.searchString)) !== null) {
            const label = parseInt(x[1].split("\)")[0].split("(")[1])
            let ftl = this.ftl.find((a) => a.layerId == label) || this.ftl[0] 
            results.push({
                betegnelse: ftl.datasetname,
                code: label,
                name: ftl.datasetname,
                data: ftl,
                onSelection: this.onSelection
            })
        }
        return results
    }
}
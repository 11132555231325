import { Localization, SettingsManager, SimpleTranslationTable } from "@viamap/viamap2-common";
import { ESInterface } from "src/compentsModus/ESInterface";
import { FilterSpec, FilterType, FilterValue } from "src/states/ExploreSearchState";
import { TEjendomsMetaData } from "src/states/ProjectState";
import { WorkBook } from "xlsx";
import { SheetFunc } from "./SheetFunc";
import { PropertyInfoInterface } from "./PropertyInfoInterface";
import { PropertyInformation } from "./PropertyInformation";
import { BBRTranslateByDataKey } from "./BBRTranslateByDataKey";
import { PropertyData } from "src/propertyInfoTemplates/PropertyInfoCollectorTypes";
import { BuildingHeight } from "./BuildingHeight";
import { UtmCoords } from "./ObliqueFunc";

export class ExportData {
    private async getPropertyDataForBFENr(bfeNr: number) {
        const elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");
        let ifx = new ESInterface(elasticIndex);
        let tmpFilters = { "bfe_nr": { title: "BFEnr", filterType: FilterType.Integer, default: ["6"], translationTable: {} } };
        let tmpFiltersValues = { "bfe_nr": [bfeNr] };
        let items = await ifx.doSearch(tmpFilters, tmpFiltersValues, SettingsManager.getSystemSetting("maxPropertiesFromElasticsearch"));
        return items && items.length === 1 ? items[0] : items;
    }

    // Translation of field names from search database where no other translations exist (in ExplorerSearchState.selectableFilterList)
    private dataFieldsTranslations: SimpleTranslationTable = {
        'da': {
            "ejf_ejereLang": "Owner eMedAdresse",
            "bbr_bebyggetAreal": "Bebygget Areal",
            "poi_dist_train": "Afstand til tog",
            "trafik_highest_place": "Højeste trafik sted",
            "handel_forretningsh": "Handelsopl Hændelse",
            "handel_maade": "Handelsopl Måde",
            "handel_status": "Handelsopl Status",
            "handel_valutaKode": "Handelsopl Valuta",
            "bfe_adresse": "Adresse",
            "koord_lat": "Breddegrad",
            "koord_lng": "Længdegrad",
            "jords_matrikelnr": "MatrikelNr",
            "jords_centroide_wkt": "Centroide",
            "bbr_tilbygningsAAr": "Tilbygningsår",
            "bbr_fredningsStatus": "Fredningsstatus",
            "bbr_ejd_bebyggetAreal": "Bebygget Areal",
            "bbr_ejd_samletBygningsAreal": "Samlet Bygningsareal",
            "bbr_ejd_bygningensSamledeBoligAreal": "Bygningens Samlede Boligareal",
            "bbr_ejd_samletErhvervsAreal": "Samlet Erhvervsareal",
            "calc_bebyggelsesPct": "Bebyggelsespct",
            "calc_prism2_grund": "Kvmpris grund",
            "calc_prism2_etagem2": "Kvmpris etageareal",
            "calc_prism2_bygningm2": "Kvmpris bygning",
            "bbr_landbrugsnotering": "Landbrugsnotering",
            "Matrikelnummer": "Matrikelnummer",
            "Ejerlavskode": "Ejerlavskode",
            "Ejerlavsnavn": "Ejerlavsnavn",
            "SFEnummer": "SFEnummer",
        },
        'en': {
            "ejf_ejereLang": "Owners",
            "bbr_bebyggetAreal": "Built area",
            "poi_dist_train": "Distance to train",
            "trafik_highest_place": "Highest trafic place",
            "handel_forretningsh": "Transaction Action",
            "handel_maade": "Transaction Type",
            "handel_status": "Transaction Status",
            "handel_valutaKode": "Transaction Currency",
            "bfe_adresse": "Address",
            "koord_lat": "Latitude",
            "koord_lng": "Longitude",
            "jords_matrikelnr": "Cadastre",
            "jords_centroide_wkt": "Centroid",
            "bbr_tilbygningsAAr": "Renovated Year",
            "bbr_fredningsStatus": "Preservation Status",
            "bbr_ejd_bebyggetAreal": "Constructed Area",
            "bbr_ejd_samletBygningsAreal": "Total Building Area",
            "bbr_ejd_bygningensSamledeBoligAreal": "Total Residential Area",
            "bbr_ejd_samletErhvervsAreal": "Total Commercial Area",
            "calc_bebyggelsesPct": "Constructed share",
            "calc_prism2_grund": "SQM-price (land)",
            "calc_prism2_etagem2": "SQM-price (floor area)",
            "calc_prism2_bygningm2": "SQM-price (total unit area)",
            "bbr_landbrugsnotering": "Agriculturalnotation",
            "Matrikelnummer": "Cadaster number",
            "Ejerlavskode": "Cadaster Region Code",
            "Ejerlavsnavn": "Cadaster Region Name",
            "SFEnummer": "SFE Number",
        }
    }

    private ownerFieldsTranslations: SimpleTranslationTable = {
        'da': {
            "EjerNavn":"EjerNavn",
            "EjerAdresse": "EjerAdresse",
            "Direktion": "Direktion",
            "EjerPostnr": "EjerPostnr",
            "EjerPostdistrikt": "EjerPostdistrikt",
            "EjerReklamebeskyttet": "EjerReklamebeskyttet",
            "EjerStatus": "EjerStatus",
            "EjerAndelPct": "EjerAndelPct",
            "EjerCvr": "EjerCvr",
            "EjerFoedt": "EjerFoedt",
            "EjerAlder": "EjerAlder",
            "Ja":"ja",
            "Nej":"nej",
            "Contact":"Kontakt",
        },
        'en': {
            "EjerNavn":"OwnerName",
            "EjerAdresse": "OwnerAddress",
            "Direktion": "Management",
            "EjerPostnr": "OwnerPostcode",
            "EjerPostdistrikt": "OwnerPostcity",
            "EjerReklamebeskyttet": "OwnerADProtected",
            "EjerStatus": "OwnerStatus",
            "EjerAndelPct": "OwnershipSharePct",
            "EjerCvr": "OwnerCvr",
            "EjerFoedt": "OwnerDateOfBirth",
            "EjerAlder": "OwnerAge",
            "Ja":"yes",
            "Nej":"no",
            "Contact":"Contact",
        }
    }

    private extraFieldsTranslations: SimpleTranslationTable = {
        'da': {
            "MatriklerTekst":"MatriklerTekst",
            "Seneste Vurderingsår":"Seneste Vurderingsår",
            "Ejendomsværdi":"Ejendomsværdi",
            "Grundværdi":"Grundværdi",
            "vurd_benyttelsesKode": "VurdBenyttelsesKode",
            "vurd_ESRejendomsnummer": "VurdEsrEjendomsNummer",
            "vurd_ESRkommunenummer": "VurdEsrKommuneNummer",
            "vurd_antalMedvurderedeLejligheder": "VurdAntalMedvurderedeLejl"
        },
        'en': {
            "MatriklerTekst":"CadasterText",
            "Seneste Vurderingsår":"Valuation Year",
            "Ejendomsværdi":"Property Value",
            "Grundværdi":"Plot Value",
            "vurd_benyttelsesKode": "ValuationUsageCode",
            "vurd_ESRejendomsnummer": "ValuationEsrPropertyNbr",
            "vurd_ESRkommunenummer": "ValuationEsrMunicipalityNbr",
            "vurd_antalMedvurderedeLejligheder": "ValuationNbrOfAppartments"
        }
    }

    private specialFieldsTranslations: SimpleTranslationTable = {
        'da': {
            "Grundhoejde":"Grundhøjde",
            "Kælderareal":"Kælderareal",
            "Kælderbygningsnr":"Kælderbygningsnr",
        },
        'en': {
            "Grundhoejde":"Ground Height",
            "Kælderareal":"Basement Area",
            "Kælderbygningsnr":"Basement Building Nr",
        }

    }

    private addCalculatedProps(props: any): any {
        try {
            if (props.sfe_registreretArealSamlet && props.bbr_bebyggetAreal) {
                // ToDo: udhuse indtil x m2 kan fratrækkes ved beregning af bebyggelsespct.
                props.calc_bebyggelsesPct = (props.bbr_bebyggetAreal * 100) / props.sfe_registreretArealSamlet;
            }
            if (props.handel_samletKoebesum && props.sfe_registreretArealSamlet) {
                props.calc_prism2_grund = props.handel_samletKoebesum / props.sfe_registreretArealSamlet;
            }
            if (props.handel_samletKoebesum && props.bbr_samletBygningsAreal) {
                props.calc_prism2_etagem2 = props.handel_samletKoebesum / props.bbr_samletBygningsAreal;
            }
            if (props.handel_samletKoebesum && props.bbr_bebyggetAreal) {
                props.calc_prism2_bygningm2 = props.handel_samletKoebesum / props.bbr_bebyggetAreal;
            }
        } catch (err) {
            console.error("Error when calculating properties: " + err);
        }
        return props;
    }

    public filterAndFormatProps(props: any, selectableFilterList: { [id: string]: FilterSpec}): any {
        let result = {}
        Object.keys(props).forEach((pr) => {
            if (!(pr.startsWith("error_")
                || pr.startsWith("time_")
                || pr.startsWith("_")
                || pr.startsWith("id_")
                || pr.startsWith("bygningspunkt_DDKNcelle")
                || ["import_has_error",
                    "geopoint",
                    "koord_status",
                    "import_batch_number",
                    "bfe_registreringFra",
                    "bfe_virkningFra",
                    "bbr_koordinater",
                    "doc_id_field",
                    "koord_x",
                    "koord_y",
                    "zone_status",
                    "time",
                    "ejf_ejere_liste",
                ].includes(pr)
            )) {
                let filterSpec = selectableFilterList[pr];
                let title = pr;
                let value = props[pr];
                if (title === "") {
                    return result;
                }
                if (filterSpec) {
                    title = Localization.getTextSpecificTable(filterSpec.title, filterSpec.translationTable);
                    if (filterSpec.filterType === FilterType.DateRange) {
                        value = (new Date(value).toLocaleDateString(Localization.getLocale(), { year: 'numeric', month: '2-digit', day: '2-digit' }));
                    } else {
                        if (filterSpec.options) {
                            if (filterSpec.translateOptions) {
                                if (!value && value !== false) {
                                    value = Localization.getTextSpecificTable(filterSpec.default[0], filterSpec.translationTable);
                                } else {
                                    value = Localization.getTextSpecificTable(value, filterSpec.translationTable);
                                }
                            } else {
                                value = filterSpec.options[value] || value;
                            }
                        }
                        // else {
                        //   if (Number(value) === value) {
                        //     value = (value as Number).toLocaleString();
                        //   }
                        // }
                    }
                } else {
                    title = Localization.getTextSpecificTable(title, this.dataFieldsTranslations);
                }
                result[title] = value;
            }
        });
        return result;
    }

    private filterOutOwnerInfo(props:any):any {
        let result = {}
        Object.keys(props).forEach((pr) => {
            if (!( ["ejf_ejereLang",
                    "ejf_cvrnr",
                    "ejf_ejere",
                    "ejf_afdoed",
                    "ejf_reklameBeskyttelse"
                ].includes(pr)
            )) {
                let key = pr;
                let value = props[pr];
                result[key] = value;
            }
        });
        return result;
    }

    private calculateAge(dob:Date) { 
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    private formatOwnerFields(ejer:any):any {
        return {
            [Localization.getTextSpecificTable("EjerNavn", this.ownerFieldsTranslations)]:ejer.navn,
            [Localization.getTextSpecificTable("EjerAdresse", this.ownerFieldsTranslations)]: ejer.adresse,
            [Localization.getTextSpecificTable("EjerPostnr", this.ownerFieldsTranslations)]: ejer.postnr,
            [Localization.getTextSpecificTable("EjerPostdistrikt", this.ownerFieldsTranslations)]: ejer.postdistrikt,
            [Localization.getTextSpecificTable("EjerReklamebeskyttet", this.ownerFieldsTranslations)]: Localization.getTextSpecificTable(ejer.reklameBeskyttet ? "Ja": "Nej", this.ownerFieldsTranslations),
            [Localization.getTextSpecificTable("EjerStatus", this.ownerFieldsTranslations)]: ejer.status,
            [Localization.getTextSpecificTable("EjerAndelPct", this.ownerFieldsTranslations)]: ejer.andelProcent,
            [Localization.getTextSpecificTable("EjerCvr", this.ownerFieldsTranslations)]: ejer.CVRNummer || "",
            [Localization.getTextSpecificTable("EjerFoedt", this.ownerFieldsTranslations)]: ejer.foedselsdato ? new Date(ejer.foedselsdato) : "",
            [Localization.getTextSpecificTable("EjerAlder", this.ownerFieldsTranslations)]: ejer.foedselsdato ? this.calculateAge(new Date(ejer.foedselsdato)) : "",
        }
    }

    private formatExtraFields(extraProps:any):any {
        return {
            [Localization.getTextSpecificTable("MatriklerTekst", this.extraFieldsTranslations)]: extraProps?.sag?.matriklerDisplayText || "",
            [Localization.getTextSpecificTable("Seneste Vurderingsår", this.extraFieldsTranslations)]: ""+(extraProps?.vurd_seneste || ""),
            [Localization.getTextSpecificTable("Ejendomsværdi", this.extraFieldsTranslations)]: extraProps?.vurd_ejendomvaerd || "",
            [Localization.getTextSpecificTable("Grundværdi", this.extraFieldsTranslations)]: extraProps?.vurd_grundvaerd || "",
            [Localization.getTextSpecificTable("vurd_benyttelsesKode", this.extraFieldsTranslations)] : extraProps?.vurd_benyttelsesKode ||"",
            [Localization.getTextSpecificTable("vurd_ESRejendomsnummer", this.extraFieldsTranslations)] : extraProps?.vurd_ESRejendomsnummer ||"",
            [Localization.getTextSpecificTable("vurd_ESRkommunenummer", this.extraFieldsTranslations)] : extraProps?.vurd_ESRkommunenummer ||"",
            [Localization.getTextSpecificTable("vurd_antalMedvurderedeLejligheder", this.extraFieldsTranslations)] : extraProps?.vurd_antalMedvurderedeLejligheder ||""
        }
    }

    private formatCvrOwner(owner, contact) {
        let stringContact:any = {}
        if (owner.CVRNummer) {
            stringContact = contact.find((a) => a.cvr == owner.CVRNummer)
        }

        return {
            [Localization.getTextSpecificTable("Direktion", this.ownerFieldsTranslations)]: stringContact?.liste?.join?.(", \n") || ""
        }
    }

    private formatKontakt(owner, contact) {
        let stringContact:any = {}
        if (owner.CVRNummer) {
            stringContact = contact.find((a) => a.cvr == owner.CVRNummer)
        }

        return {
            [Localization.getTextSpecificTable("Contact", this.ownerFieldsTranslations)]: stringContact?.punit?.[0]?.kontaktoplysninger?.join?.(", ") || ""
        }
    }

    private explorerSearchFilterValueFormatter(specs, value) {
        switch (specs.filterType) {
            case FilterType.SingleSelect:
            case FilterType.Select:
            case FilterType.SelectText:
                let valueTranslated = (value || []).map((val) => {
                    return specs.translateOptions ? Localization.getTextSpecificTable(specs.options?.[val] ,specs.translationTable) : (specs.options?.[val] ?? (val));
                })
                return  valueTranslated.join(', ');

            case FilterType.IntegerRange:
            case FilterType.DateRange:
                return value?.join(" - ");

            case FilterType.String:
            case FilterType.Integer:
                return value

            case FilterType.StaticValue:
            case FilterType.BoundingBox:
                return JSON.stringify(value);

            case FilterType.Polygon:
            case FilterType.PolygonFromLayer:
                    return "Polygon";

            default:
                return value?.join(" - ")
        }
    }

    /** Generic export function used for different exports of property data to Excel */
    async createPropertyListExcelExport(
        title: string, 
        propertyList: {bfeNr:number, [otherProps:string]:any}[], 
        createdDate: Date, 
        selectableFilterList: { [id: string]: FilterSpec },
        needToRetrievePropertyInfo: boolean,
        showProgress?: (progress:number)=>void,
        formatPropertyProps?: (property:{bfeNr:number, [otherProps:string]:any}) => any,
        extraSheets?:   {sheetName: string, rows: any[]}[],
        addGroundHeightAndBasementData?: boolean
    ): Promise<WorkBook> {
        function createCustomTimeout(seconds) {
            return new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                resolve();
              }, seconds * 1000);
            });
          }

    let progress=0;
    const getData = async (val, idx) => {
        let ownerRows:any[] = [];
        let bfeNr = val.bfe_nr || val.bfeNr;
        
        // await createCustomTimeout(2);
        let attempts = 0;
        let props 
        let extraProps

        while (true) {
            try {
                props = needToRetrievePropertyInfo ? await this.getPropertyDataForBFENr(bfeNr) : val;
                extraProps = await PropertyInfoInterface.getEjendomData(props.jords_ejerlavskode, props.jords_matrikelnr);
                let ejendomsVurderingList = await PropertyInfoInterface.vurdLookupByBfeNr(""+bfeNr);
                if (ejendomsVurderingList.length) {
                    const latestEjendomsVurdering = ejendomsVurderingList.reduce((prev, current) => (prev.år > current.år) ? prev : current);
                    extraProps.vurd_seneste = latestEjendomsVurdering.år;
                    extraProps.vurd_ejendomvaerd = latestEjendomsVurdering.ejendomværdiBeløb || 0;
                    extraProps.vurd_grundvaerd = latestEjendomsVurdering.grundværdiBeløb || 0;
                    extraProps.vurd_benyttelsesKode = "V"+latestEjendomsVurdering.benyttelseKode; // "V" added to keep as string when importing result into Mapit, as it is a code.
                    extraProps.vurd_ESRejendomsnummer = latestEjendomsVurdering.Vurderingsejendom?.ESRejendomsnummer;
                    extraProps.vurd_ESRkommunenummer = latestEjendomsVurdering.Vurderingsejendom?.ESRkommunenummer;
                    extraProps.vurd_antalMedvurderedeLejligheder = latestEjendomsVurdering.antalMedvurderedeLejligheder || 0;
                }
                break;
            } catch (error) {
                attempts++;
                if (attempts > 3) {
                    break;
                }
                await createCustomTimeout(2.5)
            }
        }

        let kontaktOpls = await Promise.all(([...new Set(extraProps?.ejere.map((a) => a.CVRNummer || "").filter((a) => a))] as string[]).map(async (a : string) => {
            return {cvr:a,punit:await PropertyInfoInterface.PEnhederforCVR(Number(a))};
        }));

        let virksomhed = await Promise.all(([...new Set(extraProps?.ejere.map((a) => a.CVRNummer || "").filter((a) => a))] as string[]).map(async (a : string) => {
            let number = Number(a);
            if (number) {
                let cvrData = await PropertyInfoInterface.getCVRData(number);
                let EjereAfCVR = (cvrData?.hits?.hits?.[0]?._source?.Vrvirksomhed?.deltagerRelation || []).map((a) => {
                    if ( !(a.deltager?.enhedstype == "PERSON")) return null
                    if ((a.organisationer?.[0]?.medlemsData?.[0]?.attributter.some((a) => (a.vaerdier.some((a) => (a.periode.gyldigTil == null || a.periode.gyldigTil == undefined) && a.vaerdi == "DIREKTØR"))))) return (a.deltager.navne[0].navn || "Ukendt navn") + " (DIREKTØR)"
                    if ((a.organisationer?.[0]?.medlemsData?.[0]?.attributter.some((a) => (a.vaerdier.some((a) => (a.periode.gyldigTil == null || a.periode.gyldigTil == undefined) && a.vaerdi == "ADM. DIR."))))) return (a.deltager.navne[0].navn || "Ukendt navn") + " (ADM. DIR.)"
                    return null
                }).filter((a) => a)
                return {cvr:a, liste:EjereAfCVR};
            }
        }));

        props = this.addCalculatedProps(props);
        let formatedProps = this.filterAndFormatProps(props, selectableFilterList);
        let propertyProps = formatPropertyProps ? formatPropertyProps(val) : {};
        let specialProps:any = {};

        // Special functionality for Faaborg kommune export
        if (addGroundHeightAndBasementData) {
            // default values
            specialProps = {...specialProps, "Grundhoejde":0, "Kælderareal": 0, "Kælderbygningsnr": 0};
            if (props.koord_x && props.koord_y) {
                let loc:UtmCoords = {x:props.koord_x,y:props.koord_y};
                let height = await BuildingHeight.getHeightInformation(loc);
                specialProps = {...specialProps, "Grundhoejde":height};
            }
            let grundPropsArr = await PropertyInfoInterface.BBRgrundForBFE(""+props.bfe_nr);
            for (let i = 0; i < grundPropsArr.length; i++) {
                let grund = grundPropsArr[i];
                let bbrBygnPropsArr = await PropertyInfoInterface.BBRbygningForGrundId((grund as any).id_lokalId);
                for (let j = 0; j < bbrBygnPropsArr.length; j++) {
                    let bbrBygnProps = bbrBygnPropsArr[j];
                    if (bbrBygnProps.etageList) {
                        for (let k = 0; k < bbrBygnProps.etageList.length; k++) {
                            let etageRec = bbrBygnProps.etageList[k];
                                let etage = etageRec.etage;
                                let kælderAreal = Math.max( 
                                    etage.eta020SamletArealAfEtage || 0,
                                    etage.eta023ArealAfLovligBeboelseIKælder || 0,
                                    etage.eta022Kælderareal || 0
                                );
                                // Save the largest basement on the ground
                                if (kælderAreal> 0 && kælderAreal > specialProps.Kælderareal) {
                                    specialProps = { ...specialProps, "Kælderareal": kælderAreal, "Kælderbygningsnr": bbrBygnProps.byg007Bygningsnummer };
                                }
                        }
                    }
                };
            };
        }
        let result = {
            ...propertyProps,
            ...this.formatCvrOwner(extraProps?.ejere.filter((a) => a.CVRNummer)?.[0] || "", virksomhed),
            ...this.formatKontakt(extraProps?.ejere.filter((a) => a.CVRNummer)?.[0] || "", kontaktOpls),
            ...ExportData.translateObjectKeys(specialProps, this.specialFieldsTranslations),
            ...formatedProps,
            ...this.formatExtraFields(extraProps),
        };

        if (extraProps && extraProps.ejere&& extraProps.ejere.length > 0) {

            let propsNoOwnerInfo = this.filterOutOwnerInfo(props);
            let formatedPropsNoOwnerInfo = this.filterAndFormatProps(propsNoOwnerInfo, selectableFilterList);
            extraProps.ejere.forEach((ejer) => {


                ownerRows.push({
                    ...this.formatOwnerFields(ejer),
                    ...this.formatCvrOwner(ejer, virksomhed),
                    ...this.formatKontakt(ejer, kontaktOpls),
                    ...formatedPropsNoOwnerInfo,
                    ...this.formatExtraFields(extraProps),
                });
            })
        } else {
            ownerRows.push(result);
        }
        progress++;
        showProgress && showProgress(progress);
        return {
            byProperty: result,
            byOwners: ownerRows,
        };
    }
    console.time("collect")
    
    let setBreak = false
    let timeout = setTimeout(() => {setBreak = true}, 10000)
    let last = 0
    let unfinished = 0
    let mixed: ({ byProperty: any; byOwners: any[];} | null)[] = propertyList.map((a) => null)
    for (let idx = 0; idx < propertyList.length; idx++) {
        while (unfinished > 200) {
            if (setBreak) {
                throw new Error("Explore data stop")
            }
            if (last !== unfinished) {
                clearTimeout(timeout)
                last = unfinished
                timeout = setTimeout(() => {setBreak = true}, 10000)
            }
            await createCustomTimeout(0.1)
        }
        clearTimeout(timeout)
        unfinished++;
        getData(propertyList[idx], idx).then((a) => {
            unfinished--;
            mixed[idx] = a
        }).catch((e) => {
            unfinished--;
            console.error(e)
        })
    }
    console.timeLog("collect", "laststarted")
    while (unfinished > 0) {
        await createCustomTimeout(0.1)
    }
    console.timeEnd("collect")
   
        let ownerRows:any[] = [];
        let rows:any[] = [];
        mixed.forEach((res) => {
            if (res == null) {
                return
            }
            rows.push(res.byProperty);
            res.byOwners.forEach((by) => {
                ownerRows.push(by);
            });
        })
        let props = {
            Title: title,
            Author: "EstateExplorer",
            CreatedDate: createdDate
        }
        let sheets = [
            {
                sheetName: "Data",
                rows: rows
            },
            {
                sheetName: "DataByOwner",
                rows: ownerRows
            },
            ...(extraSheets || []),
            {
                sheetName: "Datasources",
                rows: [
                    { "Source": "Geodatastyrelsen", "Data": "Ejerfortegnelsen (EJF), Matriklen (MAT), Ejendomsbeliggenhedsregisteret (EBR)" },
                    { "Source": "Vurderingsstyrelsen", "Data": "Bygnings- og Boligregistret (BBR)" },
                    { "Source": "Vurderingsstyrelsen", "Data": "Ejendomsvurdering (VUR)" },
                    { "Source": "Erhvervsstyrelsen", "Data": "Det Centrale Virksomhedsregister (CVR)" },
                    { "Source": "Tinglysningsretten", "Data": "Tinglysningsdata. Jvf tinglysningsloven §50c stk 5." },
                    { "Source": "Vejdirektoratet", "Data": "Trafikdata" },
                    { "Source": "Københavns Kommune", "Data": "Trafikdata" },
                    { "Source": "Open Street Map", "Data": "Ruter" }
                ]
            }
        ];
        let wb = SheetFunc.createWorkBookFromJSON(props, sheets);
        return wb;
    
}

    async createExcelExportForProject(
            viewTag: string, 
            ejnInTag: TEjendomsMetaData[], 
            createdDate: Date, 
            selectableFilterList: { [id: string]: FilterSpec },
            showProgress?: (progress:number)=>void
        ): Promise<WorkBook> {

        return this.createPropertyListExcelExport(
            `Estate Explorer Project Export (${viewTag})`,
            ejnInTag,
            createdDate,
            selectableFilterList,
            true,
            showProgress,
            (val:any) => {
                return {
                    [Localization.getText("Name")]: val.name,
                    [Localization.getText("Site description")]: val.cf_siteDescA,
                    [Localization.getText("Zoning")]: val.cf_siteDescB,
                    [Localization.getText("Overall evaluation")]: val.cf_starAcc,
                    [Localization.getText("Visibility")]: val.cf_starVis
                }
            }
        )
    }

    async createExcelExportForSearchResults(
        listItemsDisplay:any[], 
        createdDate: Date, 
        selectableFilterList:{
            [id: string]: FilterSpec;
        }, activeFilters: {
            [id:string]: FilterValue
        },
        showProgress?: (progress:number)=>void,
        addGroundHeightAndBasementData?: boolean
        ): Promise<WorkBook> {

            let extraSheets = [{
                sheetName: "Filtervalues",
                rows: Object.keys(activeFilters).map((filter_id) => {
                    let val = activeFilters[filter_id];
                    let spec = selectableFilterList[filter_id];
                    return {
                        "Filter": filter_id,
                        "Value": val ? this.explorerSearchFilterValueFormatter(spec, val) : ""
                    }
                })
            }];

            return this.createPropertyListExcelExport(
                "Estate Explorer Search Result Export",
                listItemsDisplay,
                createdDate,
                selectableFilterList,
                false,
                showProgress,
                undefined,
                extraSheets,
                addGroundHeightAndBasementData
            )
    }

    /** Generic export function used for different exports of property data to Excel */
    async createPropertyDataExcelExport(
        title: string,
        createdDate: Date,
        propertyData: any,
        showProgress?: (progress: number) => void,
        extraSheets?: { sheetName: string, rows: any[] }[]
    ): Promise<WorkBook> {
        function isObject(val) {
            if (val === null) { return false; }
            return ((typeof val === 'function') || (typeof val === 'object'));
        }

        return new Promise((resolve, reject) => {
            let props = {
                Title: title,
                Author: "EstateExplorer",
                CreatedDate: createdDate
            }
            let sheets: any[] = [];

            if (propertyData.BBRSFE?.length) {
                sheets.push({
                    sheetName: "Jordstykker",
                    rows: propertyData.BBRSFE.flatMap((a) => a.properties.jordstykke.map((b) => b.properties)).map((mat) => {
                        return ExportData.ObjectToRows(mat, ExportData.BBRKeyFilter, ExportData.DoMat2)
                    })
                })
            }


            if (propertyData.BBRByg) {
                let etager: any[] = [];
                let opgange: any[] = [];
                let bygnFremmedGrund: any[] = [];

                sheets.push({
                    sheetName: "Bygninger",
                    rows: propertyData.BBRByg.flat().filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                        Object.keys(byg).forEach((key) => {
                            let val = byg[key];
                            if (key == "etageList") {
                                val.forEach((et) => {
                                    etager.push(et.etage);
                                });
                            }
                            if (key == "opgangList") {
                                val.forEach((op) => {
                                    opgange.push(op.opgang);
                                });
                            }
                            if (key == "bygningPåFremmedGrundList") {
                                val.forEach((op) => {
                                    opgange.push(op.bygningPåFremmedGrund);
                                });
                            }
                        })
                        return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                    })
                });
                console.table(sheets[0].rows)

                // MARK: Export etager
                if (etager.length > 0) {
                    sheets.push({
                        sheetName: "Etager",
                        rows: etager.filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                            return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                        })
                    });
                }

                // MARK: export opgange
                if (opgange.length > 0) {
                    sheets.push({
                        sheetName: "Opgange",
                        rows: opgange.filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                            return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                        })
                    });
                }

                // MARK: export BygningFremmedGrund
                if (bygnFremmedGrund.length > 0) {
                    sheets.push({
                        sheetName: "BygningFremmedGrund",
                        rows: bygnFremmedGrund.filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                            return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                        })
                    });
                }
            }
            if (propertyData.BBRGrund) {
                sheets.push({
                    sheetName: "Grund",
                    rows: propertyData.BBRGrund.filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                        return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                    })
                });
            }
            if (propertyData.BBREnhed) {
                if (propertyData.DARAdresser?.length) {
                    sheets.push({
                        sheetName: "Enheder",
                        rows: propertyData.BBREnhed.flat().filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                            let newByg = {custom_Adresse: PropertyInformation.findAdresse(byg.adresseIdentificerer, propertyData.DARAdresser),...byg}
                            return ExportData.ObjectToRows(newByg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                        })
                    });
                }else {
                    sheets.push({
                        sheetName: "Enheder",
                        rows: propertyData.BBREnhed.flat().filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                            return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                        })
                    });
                }
            }
            if (propertyData.BBRGrundTeknik && propertyData.BBRGrundTeknik.flat().filter(PropertyInformation.filterOpførtGældende).length) {
                sheets.push({
                    sheetName: "Tekniske Anlæg På Grund",
                    rows: propertyData.BBRGrundTeknik.flat().filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                        return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                    })
                })
            }
            if (propertyData.BBRBygTeknik && propertyData.BBRBygTeknik.flat().filter(PropertyInformation.filterOpførtGældende).length) {
                sheets.push({
                    sheetName: "Tekniske Anlæg På Bygning",
                    rows: propertyData.BBRBygTeknik.flat().filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                        return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                    })
                })
            }
            if (propertyData.BBREnhedTeknik && propertyData.BBREnhedTeknik.flat().filter(PropertyInformation.filterOpførtGældende).length) {
                sheets.push({
                    sheetName: "Tekniske Anlæg På Enhed",
                    rows: propertyData.BBREnhedTeknik.flat().filter(PropertyInformation.filterOpførtGældende).map((byg) => {
                        return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoBBR)
                    })
                })
            }
            if (propertyData.Mat2Ejerlejlighed && propertyData.Mat2Ejerlejlighed.flat().filter((a) => a.status == "Gældende").length) {
                sheets.push({
                    sheetName: "Ejerlejligheder",
                    rows: propertyData.Mat2Ejerlejlighed.flat().filter((a) => a.status == "Gældende").map((byg) => {
                        return ExportData.ObjectToRows(byg, ExportData.BBRKeyFilter, ExportData.DoMat2)
                    })
                })
            }
            if (propertyData) {
                function VurdLatest(a, b) {
                    return ((a.ændringDato || 0) < (b.ændringDato || 0)
                    ? 1
                    : -1);
                }

                const prop = propertyData as PropertyData;
                sheets.push({
                    sheetName: "Stamdata",
                    rows: [
                        { "Info": "Ejer", "Data": prop.ViamapTingBog?.SenesteAdkomst.adkomsthaver.map((a) => a.displayText).join(", ") },
                        { "Info": "Seneste salg", "Data": prop.ViamapTingBog?.SenesteAdkomst.tinglysningsDato },
                        { "Info": "Seneste pris", "Data": prop.ViamapTingBog?.SenesteAdkomst.iAltKoebesum  },
                        { "Info": "Offentlig ejendomsværdi", "Data": prop.Vurd?.sort(VurdLatest)?.[0]?.ejendomværdiBeløb},
                        { "Info": "Offentlig grundværdi", "Data": prop.Vurd?.sort(VurdLatest)?.[0]?.grundværdiBeløb  },
                        { "Info": "Realiceret bebyggelsespct", "Data": Math.round((prop.BBRByg?.flat()?.filter(PropertyInformation.filterOpførtGældende).reduce((pre,a) => (a?.byg038SamletBygningsareal || 0) + pre ,0) || 0) / (Number(prop.Mat2?.registreretareal) || 1)*100)+"%"},
                        { "Info": "Grundskyld til kommunen", "Data": "?" },
                        { "Info": "Skat ialt", "Data": "?" },
                        { "Info": "Opførelsesår", "Data": "?" },
                        { "Info": "Antal matrikler", "Data": prop.BBRSFE?.flat().map((a) => a?.properties.jordstykke).length},
                        { "Info": "Antal bygninger", "Data": prop.BBRByg?.flat().filter(PropertyInformation.filterOpførtGældende).length  },
                        { "Info": "Antal beboelsesenheder", "Data": prop.BBREnhed?.flat().filter((a:any) => a.enh027ArealTilBeboelse).length},
                        { "Info": "Antal erhvervsenheder", "Data": prop.BBREnhed?.flat().filter((a:any) => a.enh028ArealTilErhverv).length},
                        { "Info": "Grundareal", "Data": (Number(prop.Mat2?.registreretareal) || 0)},
                        { "Info": "Bebygget areal", "Data": (prop.BBRByg?.flat()?.filter(PropertyInformation.filterOpførtGældende).reduce((pre,a) => (pre + (a?.byg041BebyggetAreal || 0)), 0) || 0)},
                        { "Info": "Bygningsareal", "Data": (prop.BBRByg?.flat()?.filter(PropertyInformation.filterOpførtGældende).reduce((pre,a) => (pre + (a?.byg038SamletBygningsareal || 0)), 0) || 0)},
                        { "Info": "Enhedsareal - Beboelse", "Data": prop.BBREnhed?.flat().filter(PropertyInformation.filterOpførtGældende).reduce((pre, a:any) => pre + (a.enh027ArealTilBeboelse || 0), 0)  },
                        { "Info": "Enhedsareal - Erhverv", "Data": prop.BBREnhed?.flat().filter(PropertyInformation.filterOpførtGældende).reduce((pre, a:any) => pre + (a.enh028ArealTilErhverv || 0), 0)  },
                        { "Info": "Areal af udnyttet del af tagetage", "Data": "'Kommer'" },
                        { "Info": "Kælderareal (dyb) < 1.25m", "Data": "'Kommer'" },
                        { "Info": "Link", "Data": "Link kommer" },
                    ]
                });
            }

            sheets = [...sheets,
            ...(extraSheets || []),
            {
                sheetName: "Datasources",
                rows: [
                    { "Source": "Geodatastyrelsen", "Data": "Ejerfortegnelsen (EJF), Matriklen (MAT), Ejendomsbeliggenhedsregisteret (EBR)" },
                    { "Source": "Vurderingsstyrelsen", "Data": "Bygnings- og Boligregistret (BBR)" },
                    { "Source": "Vurderingsstyrelsen", "Data": "Ejendomsvurdering (VUR)" },
                    { "Source": "Erhvervsstyrelsen", "Data": "Det Centrale Virksomhedsregister (CVR)" },
                    { "Source": "Tinglysningsretten", "Data": "Tinglysningsdata. Jvf tinglysningsloven §50c stk 5." },
                    { "Source": "Vejdirektoratet", "Data": "Trafikdata" },
                    { "Source": "Københavns Kommune", "Data": "Trafikdata" },
                    { "Source": "Open Street Map", "Data": "Ruter" }
                ]
            }
            ];
            let wb = SheetFunc.createWorkBookFromJSON(props, sheets);
            resolve(wb);
        });
    }


    /**
     * Assumes only valid Objects
     */
    static ObjectToRows(object: {[key:string]:any}, keyFilter:(string) => boolean, keyValTranslator:(key:string, val:string) => {[string:string]: any}) {
        let row: {} = {};
        Object.keys(object).filter(keyFilter).forEach((key) => {
            row = {...row, ...keyValTranslator(key, object[key])}
        });
        return row;
    }

    static BBRKeyFilter(key):boolean {
        const blackList = ["id_namespace","id_lokalId"]
        if (blackList.includes(key))
        return false
        return true
    }

    static BBRDefaultKeyTranslator(key:string):string {
        const [firstLetter,...OtherLetters] = key.replace(/(.*\d{3})?(.*)/,"$2");
        const Uppercased = [firstLetter.toUpperCase(),OtherLetters].flat().join("");
        const PreUpperSplitted = Uppercased.replaceAll(/()([A-ZÆØÅ])/gm," $2");
        const UppercasedNoccentcted = PreUpperSplitted.replaceAll(/([A-ZÆØÅ]) ([A-ZÆØÅ])/gm,"$1$2").replaceAll(/([A-ZÆØÅ]) ([A-ZÆØÅ])/gm,"$1$2");
        return UppercasedNoccentcted.trim()
    }

    static MATDefaultKeyTranslator(key:string):string {
        const [firstLetter,...OtherLetters] = key.replace(/(.*\d{3})?(.*)/,"$2");
        const Uppercased = [firstLetter.toUpperCase(),OtherLetters].flat().join("");
        const PreUpperSplitted = Uppercased.replaceAll(/()([A-ZÆØÅ])/gm," $2");
        const UppercasedNoccentcted = PreUpperSplitted.replaceAll(/([A-ZÆØÅ]) ([A-ZÆØÅ])/gm,"$1$2").replaceAll(/([A-ZÆØÅ]) ([A-ZÆØÅ])/gm,"$1$2");
        const DanishLetters = UppercasedNoccentcted
        .replaceAll("ae","æ")
        .replaceAll("oe","ø")
        .replaceAll("aa","å")
        .replaceAll("Ae","Æ")
        .replaceAll("Oe","Ø")
        .replaceAll("Aa","Å")
        return DanishLetters.trim()
    }

    static DoBBR(key, val) {
        if (key.startsWith("custom_")) {
            return {[key.replace("custom_","")]:val}
        }
        const defaultVal = BBRTranslateByDataKey(key, val)
        if (defaultVal.toString().includes("Object]")) {
            return {}
        }
        const defaultKey = ExportData.BBRDefaultKeyTranslator(key);
        if (defaultVal == "-") {
            console.error(`BBR Key ${key} with value ${val}, didn't match a valid options`)
            return {[defaultKey]: val}
        }
        return {[defaultKey]: defaultVal}
    }

    static DoMat2(key,val) {
        const defaultVal = val ?? "-"
        const defaultKey = ExportData.BBRDefaultKeyTranslator(key);
        return {[defaultKey]: defaultVal}
    }
    
    static downloadBlob(fileName:string, blob:Blob) {
       let url  = URL.createObjectURL(blob);
 
       var a = document.createElement('a');
       a.className = "mit-download-link";
       a.download    = fileName;
       a.href        = url;
       a.textContent = "Download "+a.download;
    
       let parent = document.body;
       parent && parent.appendChild(a);
       a.click();
       parent && parent.removeChild(a);
    }

    static translateObjectKeys(obj: {[key:string]: any}, translationTable: SimpleTranslationTable) {
        let newObj = {};
        Object.keys(obj).forEach((key) => {
            let newKey = Localization.getTextSpecificTable(key, translationTable);
            newObj[newKey] = obj[key];
        });

        return newObj;
    }
}

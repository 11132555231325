import * as go from 'gojs';

import { ReactDiagram } from 'gojs-react';
import './OwnershipDiagram.css';
import { GraphNode, GraphNodeData } from './OwnershipStructureTopDown';
import { NodeType } from './OwnershipStructure';
import { SettingsManager } from '@viamap/viamap2-common';
import { useEffect, useState } from 'react';
import { DoubleTreeLayout } from './GoDoubleTree';

/**
 * Diagram initialization method, which is passed to the ReactDiagram component.
 * This method is responsible for making the diagram and initializing the model and any templates.
 * The model's data should not be set here, as the ReactDiagram component handles that via the other props.
 * MARK: INIT Diagram
 */

function initDiagram(props:Props) {
   go.Diagram.licenseKey = SettingsManager.getSystemSetting("goJSLicenseKey_viamap_net");
   const $ = go.GraphObject.make;
   const adornment = new go.Adornment("Auto").add(
      new go.Shape("RoundedRectangle", {
         fill:null, stroke: "white", strokeWidth:3,margin:2, parameter1:10}),
         new go.Placeholder()
      )
   // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
   const diagram =
      $(go.Diagram,
         {
            hasHorizontalScrollbar: false,
            hasVerticalScrollbar: false,
            allowDelete: false,
            allowClipboard: false,
            allowCopy: false,
            allowLink: false,
            allowInsert: false,
            allowTextEdit: false,
            
            scrollsPageOnFocus: false,
            initialDocumentSpot: go.Spot.Center,
            initialViewportSpot: go.Spot.Center,
            'undoManager.isEnabled': true,  // must be set to allow for model change listening
            // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
            'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
            'contentAlignment': go.Spot.Center,
            padding: new go.Margin(500,500,500,500),
            model: new go.GraphLinksModel(
               {
                  linkKeyProperty: 'key'  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
               }),
         });
   // go.TreeLayout


   diagram.layout = $(DoubleTreeLayout, { _vertical: true,
      _directionFunction: n => {
         return !(n.data?.reverse)
      },
      _bottomRightOptions:{layerSpacing:80, setsPortSpot: false, setsChildPortSpot: false, isRealtime: false, arrangement: go.TreeLayout.ArrangementFixedRoots},
      _topLeftOptions:{layerSpacing:80, setsPortSpot: false, setsChildPortSpot: false, isRealtime: false, arrangement: go.TreeLayout.ArrangementFixedRoots}
   });

   diagram.addLayer(new go.Layer({
      name:"link",
   }))
   diagram.addLayer(new go.Layer({
      name:"boxes"
   }))

   // diagram.toolManager.mouseWheelBehavior = go.ToolManager.WheelZoom
   // diagram.toolManager.standardMouseWheel = function() {
   //    var diagram = this.diagram;
   //    var e = diagram.lastInput;
   //    if (e.control) {
   //       return
   //    }
   //    go.ToolManager.prototype.standardMouseWheel.call(this);
   // }

   // define a simple Node template
   diagram.nodeTemplate =
      $(go.Node, 'Auto', 
         {layerName:"boxes", fromSpot: new go.Spot(0.5,0.5, 0, -20), toSpot: new go.Spot(0.5,0.5, 0, -20), movable: true, isTreeExpanded: false,
            selectionAdornmentTemplate:adornment
         },
      $(go.Shape, 'RoundedRectangle' ,{name: 'SHAPE', fill: 'white', strokeWidth: 0, margin: 0, parameter1: 7},
        new go.Binding("fill", "key", v => v == 0 ? "#c3cdd4" : "white"),
        ),
      $(go.Panel, "Horizontal",
      $(go.Panel, "Spot", {margin: 0, alignment: new go.Spot(0,0), isClipping:true},
      $(go.Shape, 'RoundedRectangle', {position: new go.Point(0,0), name: 'SHAPE', fill: 'blue', strokeWidth: 0, margin: 0, width:36, height:36, parameter1: 5,
      },
         new go.Binding("fill", "type", v => colorFromType(v))),
         $(go.Picture, {source: "", width: 50, height: 50},
            new go.Binding("source", "", (v) => typeSymbol(v))
         ),
      ),
         $(go.Panel, "Vertical", {margin: new go.Margin(2,0,0,6), width: 200},
         // $(go.Panel, "Viewbox",
            // {width: 200, height: 24},
         $(go.TextBlock, // Main text
         {margin: 2, editable: false, textAlign:"left" ,maxLines:2, stroke:"#000", maxSize: new go.Size(200, 60), font:"bold 12pt sans", alignment: new go.Spot(0,0)},
         new go.Binding('text').makeTwoWay()
         ),
         // ),
         $(go.TextBlock, // Main text
            {margin: 2,editable: false, textAlign:"left" , stretch: go.GraphObject.Horizontal ,stroke:"#777", font:"7pt sans-serif"},
            new go.Binding('text', '', v => prettifyType(v))
         ),
         $("Button",
            {"ButtonBorder.fill": "#fff0", "ButtonBorder.stroke":"#fff0",click: foldIndUd, visible:false, stretch: go.GraphObject.Horizontal},
            new go.Binding('visible' ,'countChildCompanies', v => v > 0),
            $(go.TextBlock, // Main text
            {margin: 2,editable: false, textAlign:"left" , stretch: go.GraphObject.Horizontal ,stroke:"#111", font:"bold 7pt sans-serif"},
            new go.Binding('text', 'countChildCompanies', v => prettifySubgroup(v))
            ),
         ),
         {
            toolTip:  // define a tooltip for each node that displays the color as text
              $("ToolTip",
                $(go.TextBlock, { margin: 4 },
                  new go.Binding("text", "entityId"))
              ),  // end of Adornment

          },
         ),
         ),
         new go.Binding("isTreeExpanded","isTreeExpanded", (v) => v === true).makeTwoWay(),
         {click: (e, obj) => {
            if (e.event?.defaultPrevented) {
               return
            }
            props.callbackOnClickNode && props.callbackOnClickNode(obj!.part!.data);
           },
           contextClick: (e, obj) => {
            props.callbackOnRClickNode && props.callbackOnRClickNode(obj!.part!.data);
         }
         }
      );

      diagram.groupTemplate =
      $(go.Group, 'Vertical', {layerName:"boxes", movable: true, layout: $(go.GridLayout, { wrappingColumn : 1, isRealtime: false}), isSubGraphExpanded: false, isTreeExpanded: false, position: new go.Point(0,0),
         selectionAdornmentTemplate:adornment
      },
       new go.Binding("movable", "ejendomsType", (v) => v == ""),
      $(go.Panel, 'Auto',
      $(go.Shape, 'RoundedRectangle', {name: 'SHAPE', fill: 'white', strokeWidth: 0, margin: 0, parameter1: 7, portId:"",fromSpot: new go.Spot(0.5,0.5, 0, -20), toSpot: new go.Spot(0.5,0.5, 0, -20)},
        new go.Binding("fill", "key", v => v == 0 ? "#c3cdd4" : "white"),),
        
      $(go.Panel, "Horizontal",
      $(go.Panel, "Spot", {margin: 0, alignment: new go.Spot(0,0), isClipping:true},
      $(go.Shape, 'RoundedRectangle', { position: new go.Point(0,0), name: 'SHAPE', fill: 'blue', strokeWidth: 0, margin: 0, width:36, height:36, parameter1: 5,
      },
         new go.Binding("fill", "type", v => colorFromType(v))),
         $(go.Picture, { source: "", width: 50, height: 50},
            new go.Binding("source", "", (v) => typeSymbol(v))
         ),
      ),
         $(go.Panel, "Vertical", {margin: new go.Margin(2,0,0,6), width: 200},
         // $(go.Panel, "Viewbox",
         // {width: 200, height: 24},
         $(go.TextBlock, // Main text
         {margin: 2, editable: false, textAlign:"left" ,stroke:"#000", maxLines: 2, maxSize: new go.Size(200, 60), font:"bold 12pt sans", alignment: new go.Spot(0,0)},
         new go.Binding('text').makeTwoWay()
         ),
         // ),
         $(go.TextBlock, // Main text
            {margin: 2,editable: false, textAlign:"left" , stretch: go.GraphObject.Horizontal ,stroke:"#777", font:"7pt sans-serif"},
            new go.Binding('text', '', v => prettifyType(v))
         ),
         $("Button",
            { "ButtonBorder.fill": "#fff0", "ButtonBorder.stroke":"#fff0"
               ,click: function(e, tb) {
               e.event?.preventDefault()
               var group = tb.part as any;
               if (group.isTreeExpanded) {
                 group.diagram.commandHandler.collapseTree(group);
               } else {
                 group.diagram.commandHandler.expandTree(group);
            }}, visible:false, stretch: go.GraphObject.Horizontal},
            new go.Binding('visible' ,'countChildCompanies', v => v > 0),
            $(go.TextBlock, // Main text
            {margin: new go.Margin(2,2,2,0),editable: false, textAlign:"left" , stretch: go.GraphObject.Horizontal ,stroke:"#111", font:"bold 7pt sans-serif"},
            new go.Binding('text', '', v => `Fold ${v.countChildCompanies} Relationer >`)
            ),
         ),
         $("Button",
            {"ButtonBorder.fill": "#fff0", "ButtonBorder.stroke":"#fff0",click: function(e, tb) {
            e.event?.preventDefault()
               var group = tb.part as any;
               if (group.isSubGraphExpanded) {
                 group.diagram.commandHandler.collapseSubGraph(group);
               } else {
                 group.diagram.commandHandler.expandSubGraph(group);
               }}, visible:false, stretch: go.GraphObject.Horizontal},
            new go.Binding('visible' ,'countChildProperties', v => v > 0),
            $(go.TextBlock, // Main text
            {margin: new go.Margin(2,2,2,0),editable: false, textAlign:"left" , stretch: go.GraphObject.Horizontal ,stroke:"#111", font:"bold 7pt sans-serif"},
            new go.Binding('text', '',  v => `Fold ${v.countChildProperties} Ejendomme >`)
            ),
         ),
         {
            toolTip:  // define a tooltip for each node that displays the color as text
              $("ToolTip",
                $(go.TextBlock, { margin: 4 },
                  new go.Binding("text", "entityId"))
              ),  // end of Adornment

          },
         ),
         ),
         ),
         $(go.Placeholder, {padding: new go.Margin(8,1,1,1)}),
         new go.Binding("isTreeExpanded","isTreeExpanded", (v) => v === true).makeTwoWay(),
         {click: (e, obj) => {
            if (e.event?.defaultPrevented) {
               return
            }
            props.callbackOnClickNode && props.callbackOnClickNode(obj!.part!.data);
           }
         ,contextClick: (e, obj) => {
            props.callbackOnRClickNode && props.callbackOnRClickNode(obj!.part!.data);
         }
         }
      );

   diagram.linkTemplate =
      $(go.Link, {layerName:"links", routing: go.Link.Normal, toShortLength: 8, fromShortLength: -3},
         new go.Binding("toShortLength", "reverse", (a) => {return !a ? 8 : -3}),
         new go.Binding("fromShortLength", "reverse", (a) => {return !a ? -3 : 8}),
         $(go.Shape, { stroke:"#fff", strokeWidth: 2}),
         $(go.Shape, { toArrow: "Triangle", stroke:"#fff", fill:"#fff", scale: 1.2},
         new go.Binding("toArrow", "reverse", (a) => {return a ? "Feather" : "Triangle"}),
         ),
         $(go.Shape, {fromArrow:"BackwardTriangle", stroke:"#fff", fill:"#fff", scale: 1.2},
         new go.Binding("visible", "reverse", (a) => {return a ? true : false}),
         ),
         $(go.Panel, "Vertical", {visible:true, segmentOrientation: go.Link.OrientUpright }, // this whole Panel is a link label
         // new go.Binding("visible", "text", function(t) { return ((t && t.length > 0) ? true : false); }),
         // $(go.Shape, "RoundedRectangle", { fill: , stroke: "454659"}),
         $(go.TextBlock, { margin: 0, text:"", stroke:"#fff" },
           new go.Binding("text", "text")),
         $(go.TextBlock, { margin: 0, text:"", stroke:"#fff" },
            new go.Binding("text", "linkNote")
         )
       )
      );
   // diagram.isReadOnly = true;

   return diagram;
}
/**
 * This function handles any changes to the GoJS model.
 * It is here that you would make any updates to your React state, which is dicussed below.
 */
function handleModelChange(changes: any) {
   // alert('GoJS model changed!');
}

function foldIndUd(e, obj) {
   e.event.preventDefault?.()
   e.event.stopPropagation?.()
   e.event.stopImmediatePropagation?.()
   // e.diagram.startTransaction();
   var node = obj.part;
   if (node.isTreeExpanded) {
      node.diagram.commandHandler.collapseTree(node);
   } else {
      node.diagram.commandHandler.expandTree(node);
   }
   return false
}

type Props = {
   graph?:GraphNode,
   callbackOnClickNode?:(data:any) => void,
   callbackOnRClickNode?:(data:any) => void
}

export const OwnershipGraph = (props:Props) => {
   let [nodesAndLinks, setNodesAndLinks] = useState<any>({ nodeDataArray:[], linkDataArray:[] });

   useEffect(() => {
      if (props.graph) {
         let res = generateNodesAndLinks(props.graph, 0, -1, [], [], undefined, 0, true);
         setNodesAndLinks(res);
         let { nodeDataArray, linkDataArray } = res;
      }
   }, [props.graph]);


   if (props.graph) {

   return (
      <ReactDiagram
         initDiagram={() => {return initDiagram(props)}}
         divClassName='OwnerShipGraph'
         nodeDataArray={nodesAndLinks.nodeDataArray}
         linkDataArray={nodesAndLinks.linkDataArray}
         onModelChange={handleModelChange}
      />
   )
   } else {
      return(null);
   }
}

type ChildData = {
   countChildCompanies: number,
   countChildProperties: number,
   other: number
}

function generateNodesAndLinks(node: GraphNode & {data: dir}, nextNodeId: number, nextLinkId: number, nodeDataArray: {}[], linkDataArray: {}[], parentNode: number | undefined, level: number, parentIsExpanded:boolean): { nodeId: number, nextNodeId: number, nextLinkId: number, nodeDataArray: any; linkDataArray: any; } {
   let topNodeId = nextNodeId++;
   node.data.isCollapsed = true;
   if (!parentIsExpanded) {
      node.data.expandedAsDefault = false;
   }
   let childCount = node.children.reduce((a,chld) => {
      switch (chld.data.type) {
         case "Ejendom":
            return {...a, countChildProperties:(a.countChildProperties + 1)}
         case "Firma":
         case "Aktieselskab":
            return {...a, countChildCompanies:(a.countChildCompanies + 1)}
         default:
            return {...a, other:(a.other + 1)}
      }
   },{countChildCompanies:0, countChildProperties:0, other:0}) as {countChildCompanies:number, countChildProperties: number, other:number}
   nodeDataArray.push(createNode(topNodeId, {...node.data, ...childCount}));
   node.children.forEach((chld: GraphNode & {data: dir}) => {
      if (chld.data.type === "Ejendom") {
         nodeDataArray.push(createNode(nextNodeId++, chld.data, topNodeId));
         
         return
      }
      let { nodeId: ejNodeId, nextNodeId: nextNodeIdMod, nextLinkId: nextLinkIdMod, nodeDataArray: nodeDataArrayMod, linkDataArray: linkDataArrayMod } = generateNodesAndLinks(chld, nextNodeId, nextLinkId, nodeDataArray, linkDataArray, topNodeId, level + 1, node.data.expandedAsDefault)
      linkDataArrayMod.push(createLink(nextLinkIdMod--, topNodeId, ejNodeId, ""+chld.data.pct, chld.data.reverse, chld.data["linkNote"])); // chld.data.type === "Ejendom" ? "" : 
      nextNodeId = nextNodeIdMod;
      nextLinkId = nextLinkIdMod;
      nodeDataArray = nodeDataArrayMod;
      linkDataArray = linkDataArrayMod;
   });
   return { nodeId: topNodeId, nextNodeId, nextLinkId, nodeDataArray, linkDataArray };
}


type dir = {reverse?: boolean}

function createNode(nodeId: number, data:GraphNodeData & Partial<ChildData> & dir, group?:any): {} {
   let color = 'red';
   if (data) {
      switch (data.type) {
         case "Ejendom": color = '#d18d4c'; break;
         case "Person": color = '#45465d'; break;
         case "AndenDeltager": color = '#3a4b5e'; break;
         case "Firma": color = '#4f86c2'; break;
         case "Aktieselskab": color = '#4f86c2'; break;
         case "Link": color = 'lightblue'; break;
         default:
            throw new Error(`Unknown node type ${data.type || "undefined"}`);
      }
      return { key: nodeId, reverse: data.reverse || false, group: group, isGroup: (data.countResult?.ejendomme || 0) > 0, ejendomsType: data.ejendomsType || "",isTreeExpanded: (nodeId === 0)  ,isCollapsed: true, isGCollapsed: true, text: data.title, type:data.type, entityId: data.entityId, color: color, loc: '0 0', lat:data.lat, lng:data.lng, expandedAsDefault:data.expandedAsDefault, companySubCount:(data.countResult?.virksomheder||1)-1, propertySubCount:data.type != "Ejendom" ? (data.countResult?.ejendomme ||0) : 0 
      ,countChildProperties: data.countChildProperties || 0,countChildCompanies: (data.countChildCompanies || 0) +(data.other || 0)
   };
   } else {
      return { key: nodeId, isCollapsed:true, text: "no data!", type:"undefined", entityId: 0, color: color, loc: '0 0' };
   }
}

function createLink(linkId: number, from: number, to: number, pct: string, reverse?: boolean, linkNote?:string): {} {
   return { key: linkId, from: from, to: to, text:  pct && Number(pct) ? pct+"%" : "?%", linkNote: linkNote, reverse: !!reverse };
}

function prettifySubgroup(l) {
   return `Fold ${l} Releationer >`

}

function colorFromType(l: any) {
   let t = {type: l}
   if (t.type) {
      switch (t.type as NodeType) {
         case "Ejendom": return '#d18d4c' 
         case "Person": return '#45465d' 
         case "Firma": return '#4f86c2' 
         case "AndenDeltager": return '#3a4b5e'
         case "Aktieselskab": return '#4f86c2'  
         case "Link": return 'lightblue' 
      }
   }
   return "white"
}

function prettifyType(l: any) {
   if (l.type) {
      switch (l.type as NodeType) {
         case "Ejendom": {
            return l.ejendomsType;
         }
         case "Person": {

            return "Person"
         }
         case "Firma": return "Firma"
         case "Aktieselskab": return "Aktieselskab"
         case "AndenDeltager": return "Undenlandsk Aktør"
         case "Link": return "Link"
      }
   }
   return "white"
}

function typeSymbol(l) {
   if (l.type) {
      switch (l.type as NodeType) {
         case "Ejendom": return new URL("/EjerskabDiagramIkoner/portefolje.svg", import.meta.url).href
         case "Person": return new URL("/EjerskabDiagramIkoner/person.svg", import.meta.url).href
         case "Firma": return new URL("/EjerskabDiagramIkoner/virksomhed.svg", import.meta.url).href
         case "AndenDeltager": return new URL("/EjerskabDiagramIkoner/globus.svg", import.meta.url).href
         case "Aktieselskab": return new URL("/EjerskabDiagramIkoner/virksomhed.svg", import.meta.url).href
         case "Link": return new URL("/EjerskabDiagramIkoner/noegletal.svg", import.meta.url).href
      }
   }
   return new URL("/EjerskabDiagramIkoner/noegletal.svg", import.meta.url).href
}